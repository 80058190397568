import { action, makeAutoObservable, observable } from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import React, { createContext, useContext } from "react";

import { Report } from "@/types/evaluate";

interface MatrixComparison {
  cardIndex: number;
  differences: string[][];
}
const parseScore = (report: Report): number | null => {
  if (report.score == null) return null;
  return typeof report.score === "object" ? report.score.value : +report.score;
};

export const getFormattedScore = (report: Report): string => {
  const score = parseScore(report);
  return score === null ? "N/A" : score.toFixed(2);
};

type ComparisonResult = {
  percentageDifference: number;
  matrixComparisons?: MatrixComparison[];
};

class CompareReportsStore {
  @observable selectedBatchA: Report | null = null;
  @observable selectedBatchB: Report | null = null;
  batches: Report[] = [];
  comparisonResult: ComparisonResult | null = null;
  isLoading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this, {
      setSelectedBatch: action,
      setLoading: action,
      setError: action,
      setBatches: action,
      compareSelectedBatches: action,
    });
  }

  setSelectedBatch = (id: number, type: "A" | "B") => {
    const selectedBatch = this.batches.find((batch) => batch.id === id);
    if (selectedBatch) {
      if (type === "A") {
        this.selectedBatchA = selectedBatch;
      } else {
        this.selectedBatchB = selectedBatch;
      }
      this.compareSelectedBatches();
    }
  };

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  setError = (error: string | null) => {
    this.error = error;
  };

  setBatches = (batches: Report[]) => {
    this.batches = [...new Set(batches)];
  };

  addBatch = (batch: Report) => {
    if (!this.batches.some((existingBatch) => existingBatch.id === batch.id)) {
      this.batches.push(batch);
    }
  };

  reset() {
    this.selectedBatchA = null;
    this.selectedBatchB = null;
    this.batches = [];
    this.comparisonResult = null;
    this.isLoading = true;
    this.error = null;
  }

  compareSelectedBatches = () => {
    if (!this.selectedBatchA || !this.selectedBatchB) return;
    const batchAScore = parseScore(this.selectedBatchA);
    const batchBScore = parseScore(this.selectedBatchB);

    if (batchAScore !== null && batchBScore !== null) {
      this.comparisonResult = {
        percentageDifference: batchBScore - batchAScore,
      };
    } else {
      this.comparisonResult = null;
    }
  };

  get hasComparisonResult() {
    return this.comparisonResult !== null;
  }

  get selectedBatches() {
    return {
      batchA: this.selectedBatchA,
      batchB: this.selectedBatchB,
    };
  }
}

const CompareReportsContext = createContext<CompareReportsStore | null>(null);

export const CompareReportsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const store = useLocalObservable(() => new CompareReportsStore());

  return (
    <CompareReportsContext.Provider value={store}>
      {children}
    </CompareReportsContext.Provider>
  );
};

export const useCompareReportsStore = () => {
  const store = useContext(CompareReportsContext);
  if (!store) {
    throw new Error(
      "useCompareReportsStore must be used within a CompareReportsProvider",
    );
  }
  return store;
};
