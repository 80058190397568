import { FC } from "react";

const RuntimeQuota: FC = () => (
  <ul className="list-disc pl-5">
    <li>
      <strong>Input code size:</strong> 1MiB
    </li>
    <li>
      <strong>Size of stdin:</strong> 10MiB
    </li>
    <li>
      <strong>Size of stdout:</strong> 20MiB
    </li>
    <li>
      <strong>Size of stderr:</strong> 10MiB
    </li>
    <li>
      <strong>Number of environment variables:</strong> 100
    </li>
    <li>
      <strong>Environment variable key size:</strong> 4KiB
    </li>
    <li>
      <strong>Environment variable value size:</strong> 100KiB
    </li>
    <li>
      <strong>Number of arguments:</strong> 100
    </li>
    <li>
      <strong>Argument size:</strong> 100KiB
    </li>
    <li>
      <strong>Execution duration:</strong> 30 seconds
    </li>
    <li>
      <strong>Memory consumption:</strong> 128MiB
    </li>
  </ul>
);

export default RuntimeQuota;
