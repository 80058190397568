import { request } from "@/schemas";
import { responseIsFunctionOrTool } from "@/utils/logUtils";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { InputVariablesPopoverContent } from "../InputVariablesPopoverContent";
import { Popover, PopoverTrigger } from "../ui/popover";

const ColoredScore = ({ score }: { score: number }) => {
  const color =
    score >= 80
      ? ["bg-green-50", "text-green-500"]
      : score >= 60
      ? ["bg-orange-50", "text-orange-500"]
      : ["bg-red-50", "text-red-500"];
  return (
    <span
      className={`${color[0]} rounded-full px-4 py-2 font-medium ${color[1]}`}
    >
      {score}
    </span>
  );
};

const columnHelper = createColumnHelper<request.PromptTemplate>();

const columns = [
  columnHelper.accessor("engine", {
    header: "Model Name",
    cell: ({ row, getValue }) => (
      <Link
        className="ml-2 hover:text-gray-500"
        to={`/workspace/${row.original.workspace_id}/request/${row.original.id}`}
      >
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("prompt_input_variables", {
    header: "Input Variables",
    cell: ({ getValue }) => {
      const value = getValue();

      return value ? (
        <div className="x-36 flex flex-wrap gap-1 md:w-[200px] 2xl:w-[300px]">
          <Popover>
            <PopoverTrigger asChild>
              <div className="group flex cursor-pointer flex-wrap gap-1 rounded-md bg-none px-2 py-1 text-xs hover:bg-gray-100 hover:shadow-sm">
                {Object.keys(value)
                  .slice(0, 7)
                  .map((k) => (
                    <div
                      key={k}
                      className="rounded-full border border-gray-200 bg-gray-200 px-2 py-1 font-medium text-gray-700 group-hover:border-gray-300 group-hover:bg-gray-300 group-hover:text-gray-600"
                    >
                      {k}
                    </div>
                  ))}
                {Object.keys(value).length > 7 && (
                  <div className="rounded-full border border-gray-200 bg-gray-200 px-2 py-1 font-medium text-gray-700 group-hover:border-gray-300 group-hover:bg-gray-300 group-hover:text-gray-600">
                    +{Object.keys(value).length - 7} more
                  </div>
                )}
              </div>
            </PopoverTrigger>
            <InputVariablesPopoverContent variables={value} />
          </Popover>
        </div>
      ) : null;
    },
  }),
  columnHelper.accessor("request_start_time", {
    header: "Request Start Time",
    cell: ({ row, getValue }) => (
      <Link
        className=" hover:text-gray-500"
        to={`/workspace/${row.original.workspace_id}/request/${row.original.id}`}
      >
        {moment.utc(getValue()).fromNow()}
      </Link>
    ),
  }),
  columnHelper.accessor("score", {
    header: "Score",
    cell: ({ row, getValue }) => {
      const score: any = getValue();
      return score ? (
        <div className="flex w-36 flex-wrap gap-1">
          <TooltipPrimitive.Provider delayDuration={100}>
            <TooltipPrimitive.Root>
              <TooltipPrimitive.Trigger className="text-blue-500 hover:text-blue-400">
                {score?.default && <ColoredScore score={score.default} />}
              </TooltipPrimitive.Trigger>
              <TooltipPrimitive.Portal>
                <TooltipPrimitive.Content>
                  <div className="max-h-36 max-w-lg overflow-scroll rounded-md bg-white p-8 shadow-md">
                    {score?.others && Object.keys(score?.others).length > 0 ? (
                      <>
                        <div className="mb-2 border-b border-gray-200 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-800">
                          Other scores
                        </div>
                        {Object.keys(score?.others).map((k) => (
                          <div key={k}>
                            <div className="font-mono text-sm text-gray-700">
                              {`${k}: ${score?.others[k]}`}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No additional scores.</p>
                    )}
                  </div>
                </TooltipPrimitive.Content>
              </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
          </TooltipPrimitive.Provider>
        </div>
      ) : null;
    },
  }),
  columnHelper.accessor("request_response", {
    header: "Response Preview",
    cell: ({ row, getValue }) => {
      if (row.original.prompt_version) {
        const request = row.original;
        const previewString = request.response_string;
        const promptTemplate = request.prompt_version?.prompt_template;
        const isFunctionOrTool =
          promptTemplate?.type === "chat"
            ? !!promptTemplate.function_call || !!promptTemplate.tool_choice
            : false;
        return (
          <Link
            className="hover:text-gray-500"
            to={`/workspace/${row.original.workspace_id}/request/${row.original.id}`}
          >
            <div>
              <div
                className={`line-clamp-4 max-w-sm ${
                  isFunctionOrTool ? "font-mono" : ""
                }`}
              >
                {previewString}
                {isFunctionOrTool && "()"}
              </div>
            </div>
          </Link>
        );
      }
      if (row.original.is_chat) {
        const { response_text } = row.original;
        if ("response_json" in row.original) {
          const request = row.original;
          const template = request.request_response;
          const role = template?.role || "";
          const previewString = request.response_string;
          const isFunctionOrTool = responseIsFunctionOrTool(request);

          return (
            <Link
              className="hover:text-gray-500"
              to={`/workspace/${row.original.workspace_id}/request/${row.original.id}`}
            >
              <div>
                <div className={`font-bold`}>{role.toUpperCase()}</div>
                <div
                  className={`line-clamp-4 max-w-sm ${
                    isFunctionOrTool ? "font-mono" : ""
                  }`}
                >
                  {previewString}
                  {isFunctionOrTool && "()"}
                </div>
              </div>
            </Link>
          );
        }
        const role =
          typeof response_text === "object" &&
          response_text !== null &&
          "parts" in response_text
            ? ""
            : response_text.role;
        const content =
          typeof response_text === "object" &&
          response_text !== null &&
          "parts" in response_text
            ? ""
            : response_text.content;
        const label = role.toUpperCase();
        return (
          <Link
            className=" hover:text-gray-500"
            to={`/workspace/${row.original.workspace_id}/request/${row.original.id}`}
          >
            <div>
              <div className="font-bold">{label}</div>
              <div className="line-clamp-4 max-w-sm">{content}</div>
            </div>
          </Link>
        );
      } else {
        // Handle non-chat prompts
        // Anthropic: value.completion
        // OpenAI: value.choices[0].text
        const value = getValue() as any;
        const completionText =
          value?.choices?.[0]?.text || value?.completion || "";
        return (
          <Link
            className=" hover:text-gray-500"
            to={`/workspace/${row.original.workspace_id}/request/${row.original.id}`}
          >
            <div>
              <div className="line-clamp-4 max-w-sm">{completionText}</div>
            </div>
          </Link>
        );
      }
    },
  }),
];

type PromptTemplateVersionRequestsTableProps = {
  data: any;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  sorting: SortingState;
};

export const PromptTemplateVersionRequestsTable = ({
  data,
  setSorting,
  sorting,
}: PromptTemplateVersionRequestsTableProps) => {
  return useReactTable({
    columns,
    data: data.items || [],
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    state: {
      sorting,
    },
    initialState: {
      sorting: [
        {
          id: "request_start_time",
          desc: false,
        },
      ],
    },
  });
};
