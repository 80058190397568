import { useMemo } from "react";
import { ScoreData } from "../types";

const useScoreCards = (
  beforeScore?: ScoreData,
  afterScore?: ScoreData,
  showAll?: boolean,
  currentPage?: number,
) => {
  return useMemo(() => {
    if (showAll) {
      const beforeMatrix = beforeScore?.score_matrix || [];
      const afterMatrix = afterScore?.score_matrix || [];
      const maxLength = Math.max(beforeMatrix.length, afterMatrix.length);

      return {
        before: beforeMatrix.concat(
          Array(Math.max(0, maxLength - beforeMatrix.length)).fill([]),
        ),
        after: afterMatrix.concat(
          Array(Math.max(0, maxLength - afterMatrix.length)).fill([]),
        ),
      };
    }
    return {
      before: [beforeScore?.score_matrix?.[currentPage || 0] || []],
      after: [afterScore?.score_matrix?.[currentPage || 0] || []],
    };
  }, [beforeScore, afterScore, currentPage, showAll]);
};

export default useScoreCards;
