import { useState, useMemo } from "react";
import { ScoreData } from "../types";

const useMatrixPagination = (
  beforeScore?: ScoreData,
  afterScore?: ScoreData,
) => {
  const [currentPage, setCurrentPage] = useState(0);

  const maxPages = useMemo(() => {
    const beforeLength = beforeScore?.score_matrix?.length || 0;
    const afterLength = afterScore?.score_matrix?.length || 0;
    return Math.max(beforeLength, afterLength);
  }, [beforeScore, afterScore]);

  const handlePrevPage = () => {
    setCurrentPage((prev: number) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNextPage = () => {
    setCurrentPage((prev: number) => (prev < maxPages - 1 ? prev + 1 : prev));
  };

  return { currentPage, maxPages, handlePrevPage, handleNextPage };
};

export default useMatrixPagination;
