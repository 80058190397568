import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import NotFoundMessage from "@/components/ui/not-found";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useGetBlueprints, useUpdateReportComment } from "@/queries";
import { Report, ReportColumn } from "@/types/evaluate";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportHeader } from "../ReportHeader";
import { ReportScoreCard } from "../ReportScoreCard";
import EvalTableSection from "../TableComponents/EvalTableSection";
import ModalRouter from "../TableComponents/NewColumnModal/ModalRouter";
import { ModalRouterProvider, useModalRouter } from "../modal-router-context";
import { ReportProvider, useReportContext } from "../report-context";

const ViewReport = () => {
  const { reportId } = useParams();
  const userContext = useUser();
  const reportIdNumber = reportId ? Number(reportId) : null;

  if (!reportIdNumber) {
    return (
      <NotFoundMessage
        backLink={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
        title="Report not found"
        subtitle="Evaluation report does not exist on this workspace."
        backLinkMessage="Go back to Evaluate"
      />
    );
  }

  return (
    <ReportProvider reportId={reportIdNumber} editable={false}>
      <ModalRouterProvider>
        <ViewReportHelper />
      </ModalRouterProvider>
    </ReportProvider>
  );
};

const ViewReportHelper = () => {
  const reportContext = useReportContext();
  const reportId = reportContext.reportId;
  const auth = useAuth();
  const userContext = useUser();

  const reportMetadata: Report | null = reportContext.reportMetadata;
  const reportIsLoading: boolean = reportContext.reportIsLoading;

  const reportColumns: ReportColumn[] = reportContext.reportColumns;
  const reportColumnsIsLoading: boolean = reportContext.reportColumnsIsLoading;
  const reportCellsIsLoading: boolean = reportContext.reportCellsIsLoading;

  const userToken = auth!.userToken!;
  const { data: blueprintsData } = useGetBlueprints(
    userToken,
    userContext?.activeWorkspaceId,
  );
  const parentReportName = blueprintsData?.reports?.find(
    (report: Report) => report.id === reportMetadata?.parent_report_id,
  )?.name;

  const [reportComment, setReportComment] = useState("Loading...");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    mutate: updateReportComment,
    isLoading: updateReportCommentIsLoading,
  } = useUpdateReportComment(userToken, reportId);
  const { isOpen, selectedColumn, setIsOpen } = useModalRouter();

  useEffect(() => {
    if (reportMetadata && isInitialLoad) {
      setReportComment(reportMetadata.comment || "");
      setIsInitialLoad(false);
    }
  }, [reportMetadata, isInitialLoad]);

  if (reportIsLoading) {
    return <LoadingSpinner />;
  }

  if (!reportMetadata || !reportId) {
    return (
      <NotFoundMessage
        backLink={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
        title="Report not found"
        subtitle="Evaluation report does not exist on this workspace."
        backLinkMessage="Go back to Evaluate"
      />
    );
  }

  const originalComment = reportMetadata?.comment || "";
  const commentIsUnchanged = reportComment === originalComment;
  const commentArea = (
    <div className="relative mb-2 h-[50%] w-full">
      <label className="pl-1 text-sm font-semibold text-gray-700">
        Report Comment
      </label>
      <textarea
        placeholder="Add a comment..."
        value={reportComment}
        onChange={(e) => setReportComment(e.target.value)}
        className="h-[100%] min-h-[96px] w-full rounded-md border border-gray-300 px-4 py-2 text-base text-gray-700 placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
      />

      <div className="absolute -bottom-3 right-2">
        <Button
          size="sm"
          variant="default"
          className="disabled:bg-gray-400"
          disabled={commentIsUnchanged}
          onClick={() => updateReportComment(reportComment)}
          isLoading={updateReportCommentIsLoading}
        >
          Update
        </Button>
      </div>
    </div>
  );

  const score = reportMetadata?.score;
  const scoreMatrix = reportMetadata?.score_matrix;
  const scoreError = reportMetadata?.score_calculation_error;

  // const scoreArea = reportScoreBox(score, reportColumns);
  const scoreArea = (
    <ReportScoreCard
      score={score}
      scoreError={scoreError}
      scoreMatrix={scoreMatrix}
      reportColumns={reportColumns}
    />
  );
  const promptId = reportMetadata?.prompt_registry_id;
  const promptVersionNumber = reportMetadata?.prompt_version_number;

  return (
    <>
      <ReportHeader
        createdAt={reportMetadata.created_at}
        datasetInfo={reportContext.datasetInfo}
        isBlueprint={false}
        isDeleted={reportMetadata.deleted}
        lastUpdated={reportMetadata.updated_at}
        parentReportId={reportMetadata.parent_report_id || -1}
        parentReportName={parentReportName || ""}
        promptId={promptId}
        promptVersionNumber={promptVersionNumber}
        reportId={reportId}
        reportName={reportMetadata.name}
      />
      {reportColumnsIsLoading || reportCellsIsLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="px-1 pb-8">
          <div className="flex space-x-8">
            <div className="flex-1">{commentArea}</div>
            <div className="w-px bg-gray-300"></div>
            <div className="">{scoreArea}</div>
          </div>
          <TooltipProvider>
            <EvalTableSection searchable />
          </TooltipProvider>
          <ModalRouter
            availableColumns={reportColumns || []}
            reportId={reportId}
            oldColumn={selectedColumn!}
            open={isOpen}
            onOpenChange={setIsOpen}
            editable={false}
          />
        </div>
      )}
    </>
  );
};

export default ViewReport;
