import { useCallback } from "react";
import { useContentArea } from "../../content-area-context";

const useKeyboardHandling = (isComposing: boolean, widgetsEnabled = true) => {
  const content = useContentArea();

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      let toBeInputted = event.currentTarget.innerHTML;
      if (toBeInputted === "\n") toBeInputted = ""; // onSelectAll + Backspace, default browser injects a default newline
      content.setContent(toBeInputted);
    },
    [content],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (widgetsEnabled && event.key === "/" && !isComposing) {
        const range = content.savedSelection!;
        let rect = range.getBoundingClientRect();
        if (rect.width === 0 && rect.height === 0) {
          // If the range has no dimensions, get the position of the cursor
          const span = document.createElement("span");
          range.insertNode(span);
          rect = span.getBoundingClientRect();
          span.parentNode?.removeChild(span);
        }
        if (rect) {
          content.setContextMenuPosition({
            top: rect.bottom,
            left: rect.left,
          });
          content.setShowContextMenu(true);
        }
        return;
      } else if (
        content.showContextMenu &&
        event.key !== "ArrowUp" &&
        event.key !== "ArrowDown"
      ) {
        content.setShowContextMenu(false);
      }

      if (event.key === "Enter") {
        return;
      }

      if (event.key !== "Backspace" && event.key !== "Delete") {
        return;
      }
      if (!event.currentTarget.innerHTML) {
        event.preventDefault();
        return;
      }
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;

      const range = selection.getRangeAt(0);

      // Check if caret is at beginning
      const isAtBeginning =
        range.collapsed &&
        range.startOffset === 0 &&
        !range.startContainer.previousSibling;

      if (isAtBeginning) event.preventDefault(); // prevent backspacing at beginning of container with uncollapsed selection
    },
    [content, isComposing, widgetsEnabled],
  );

  return { handleKeyDown, handleInput };
};

export default useKeyboardHandling;
