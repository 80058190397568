import { LanguageType } from "@/types/evaluate";
import { useMonaco } from "@monaco-editor/react";
import { useRef, useCallback, useEffect } from "react";

const useMonacoCompletion = (
  language: LanguageType,
  columnNamesInScope: string[],
) => {
  const monaco = useMonaco();
  const completionProviderRef = useRef<any | null>(null);

  const configureMonaco = useCallback(() => {
    if (monaco) {
      if (completionProviderRef.current) {
        completionProviderRef.current.dispose();
        completionProviderRef.current = null;
      }

      if (language === "PYTHON") {
        completionProviderRef.current =
          monaco.languages.registerCompletionItemProvider("python", {
            provideCompletionItems: () => ({
              suggestions: [
                {
                  label: "data",
                  kind: monaco.languages.CompletionItemKind.Variable,
                  insertText: "data",
                  detail: "Dictionary containing row data",
                  documentation:
                    "The data object contains the row data for the current execution.",
                },
                ...columnNamesInScope.map((name) => ({
                  label: `data['${name}']`,
                  kind: monaco.languages.CompletionItemKind.Property,
                  insertText: `data['${name}']`,
                  detail: `Value of ${name} column`,
                  documentation: `Access the value of the ${name} column for the current row.`,
                })),
              ],
            }),
          });
      } else if (language === "JAVASCRIPT") {
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
          ...monaco.languages.typescript.javascriptDefaults.getCompilerOptions(),
          noLib: true,
          allowNonTsExtensions: true,
          checkJs: true,
          strict: true,
        });

        completionProviderRef.current =
          monaco.languages.registerCompletionItemProvider("javascript", {
            provideCompletionItems: () => ({
              suggestions: [
                {
                  label: "data",
                  kind: monaco.languages.CompletionItemKind.Variable,
                  insertText: "data",
                  detail: "Dictionary containing row data",
                  documentation:
                    "The data object contains the row data for the current execution.",
                },
                ...columnNamesInScope.map((name) => ({
                  label: `data.${name}`,
                  kind: monaco.languages.CompletionItemKind.Property,
                  insertText: `data.${name}`,
                  detail: `Value of ${name} column`,
                  documentation: `Access the value of the ${name} column for the current row.`,
                })),
              ],
            }),
          });
      }
    }
  }, [monaco, language, columnNamesInScope]);

  useEffect(() => {
    configureMonaco();
    return () => {
      if (completionProviderRef.current) {
        completionProviderRef.current.dispose();
        completionProviderRef.current = null;
      }
    };
  }, [configureMonaco]);
};

export default useMonacoCompletion;
