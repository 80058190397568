import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";

const ScrollButton = ({
  direction,
  onClick,
}: {
  direction: "left" | "right";
  onClick: () => void;
}) => (
  <Button
    onClick={onClick}
    className={`absolute top-1/2 -translate-y-1/2 transition-opacity duration-300 ${
      direction === "left" ? "left-1" : "right-1"
    }`}
    aria-label={`Scroll ${direction}`}
    size="tinyIcon"
    variant="ghostGray"
  >
    {direction === "left" ? <ChevronLeft /> : <ChevronRight />}
  </Button>
);

export default ScrollButton;
