import { ModalContent } from "@/components/Evaluate/TableComponents/NewColumnModal/ModalRouter";
import { BandaidProvider } from "@/components/Evaluate/TableComponents/utils/BandaidContext";
import { Button } from "@/components/ui/button";
import { ToastType } from "@/enums";
import { ReportColumn } from "@/types/evaluate";
import { displayToast } from "@/utils/toast";
import { XIcon } from "@heroicons/react/solid";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { WorkflowNode } from "../../types";
import {
  getAllLinkedDependencies,
  withConfigurationIdToNameRemapping,
} from "../../utils";
import EditableNodeLabel from "../Canvas/Node/NodeTypes/BasicNode/EditableNodeLabel";
import { useWorkflow } from "../workflow-context";
import NodeTypeSelector from "./NodeTypeSelector";

interface SidebarProps {
  readonly?: boolean;
}

const MIN_SIDEBAR_WIDTH = 350;
const MAX_SIDEBAR_WIDTH = 800;

const Sidebar = observer((props: SidebarProps) => {
  const { readonly } = props;
  const workflow = useWorkflow();
  const { isSidebarOpen, activeNode, input_variables } = workflow;
  const [sidebarWidth, setSidebarWidth] = useState(MIN_SIDEBAR_WIDTH);
  const resizeRef = useRef<HTMLDivElement>(null);
  const isResizing = useRef(false);
  const startX = useRef(0);
  const startWidth = useRef(0);

  const patchColumnData = (patchData: any) => {
    if (activeNode && typeof patchData === "function") {
      workflow.replaceNode(
        activeNode.name,
        patchData(activeNode) as WorkflowNode,
      );
    } else if (activeNode) {
      workflow.replaceNode(activeNode.name, {
        ...patchData,
      });
    }
  };

  const inputVariables = Object.keys(input_variables || {}).map((key, i) => ({
    id: i,
    name: key,
    column_type: "DATASET",
    configuration: {},
    position: -1,
    is_part_of_score: false,
    score: null,
    report_id: -2,
  })) as ReportColumn[];

  const handleCloseSidebar = () => {
    runInAction(() => {
      workflow.setActiveNode(undefined);
      workflow.closeSidebar();
    });
  };

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isResizing.current) return;

    const diffX = startX.current - e.pageX;
    const newWidth = Math.min(
      Math.max(startWidth.current + diffX, MIN_SIDEBAR_WIDTH),
      MAX_SIDEBAR_WIDTH,
    );
    setSidebarWidth(newWidth);
  }, []);

  const stopResizing = useCallback(() => {
    isResizing.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", stopResizing);
  }, [handleMouseMove]);

  const startResizing = useCallback(
    (e: React.MouseEvent) => {
      isResizing.current = true;
      startX.current = e.pageX;
      startWidth.current = sidebarWidth;
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", stopResizing);
    },
    [handleMouseMove, sidebarWidth, stopResizing],
  );

  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", stopResizing);
    };
  }, [handleMouseMove, stopResizing]);

  useEffect(() => {
    setSidebarWidth(MIN_SIDEBAR_WIDTH);
  }, [isSidebarOpen, activeNode?.id]);

  return (
    <div
      className={`absolute right-0 top-0 z-[2] h-full overflow-y-auto bg-white shadow-md transition-transform duration-300 ease-in-out ${
        isSidebarOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ width: sidebarWidth }}
    >
      <div
        ref={resizeRef}
        className="absolute left-0 top-0 h-full w-1 cursor-ew-resize select-none  border-gray-200 transition-colors duration-150  hover:border-blue-500/50 hover:bg-blue-500/10 active:border-blue-600 active:bg-blue-500/20"
        onMouseDown={startResizing}
      ></div>

      <div className="p-4 pt-12">
        <Button
          variant={"ghost"}
          size={"tinyIcon"}
          className="absolute right-4 top-4"
          onClick={handleCloseSidebar}
        >
          <XIcon className="h-5 w-5" />
        </Button>
        <div className="mb-4 flex flex-col">
          <div className="flex-grow">
            <EditableNodeLabel
              workflow={workflow}
              initialLabel={activeNode?.name || ""}
              node={activeNode!}
              key={activeNode?.name}
              className="text-lg font-semibold"
            />
          </div>
          {readonly && (
            <div className="mt-2 flex items-center justify-center whitespace-nowrap rounded-full bg-gray-100 px-3 py-1 text-xs font-medium text-gray-600">
              Read-only
            </div>
          )}
        </div>
        <NodeTypeSelector readonly={readonly} />
        <BandaidProvider readonly={readonly}>
          <div
            className={`mb-2 ${readonly && "pointer-events-none select-none"}`}
          >
            <ModalContent
              key={activeNode?.name}
              currentModalNavigation={"setup-column"}
              newColumnData={
                activeNode
                  ? {
                      ...activeNode,
                      configuration: withConfigurationIdToNameRemapping(
                        activeNode.configuration || {},
                        workflow.nodes,
                      ),
                    }
                  : ({ configuration: {} } as any)
              }
              patchColumnData={patchColumnData}
              setCurrentModalNavigation={function (step: string): void {
                throw new Error("Function not implemented.");
              }}
              editable={true}
              availableColumns={(() => {
                const allDependencies = activeNode?.dependencies
                  ? getAllLinkedDependencies(
                      workflow.getNodeById.bind(workflow),
                      activeNode.id,
                    )
                  : [];

                return [
                  ...allDependencies.map((d) => {
                    const node = workflow.getNodeById(d);
                    return {
                      id: node?.id || d, // fallback to input variables
                      name: node?.name || d,
                      column_type: node?.node_type,
                    } as unknown as ReportColumn;
                  }),
                  ...inputVariables,
                ];
              })()}
              saveColumnAction={(args) => {
                patchColumnData({ ...args, id: activeNode?.id });
                const newReference = workflow.getNodeById(activeNode?.id!);
                try {
                  if (activeNode) {
                    workflow.saveNode(newReference!).then((success) => {
                      if (success)
                        displayToast(
                          "Node saved successfully",
                          ToastType.success,
                        );
                    });
                  }
                } catch (error) {
                  console.error("Error saving node:", error);
                }
              }}
              setOpen={function (open: boolean): void {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
        </BandaidProvider>
      </div>
    </div>
  );
});

export default Sidebar;
