import { RequestLogFilterParams } from "@/types/request-log-filter-params";

interface UrlSearchParamsDisplayProps extends RequestLogFilterParams {
  title?: string;
}

const UrlSearchParamsDisplay = ({
  end_time,
  limit,
  metadata_and,
  metadata_or,
  prompt_template,
  q,
  scores,
  starred,
  start_time,
  tags_and,
  tags_or,
  title = "Filters",
}: UrlSearchParamsDisplayProps) => {
  return (
    <div className="py-4">
      <h2 className="pb-2 text-base font-bold text-gray-700">{title}</h2>
      <div className="grid grid-cols-4 gap-2 whitespace-pre-wrap text-sm text-gray-500">
        <div className="col-span-1">
          <span className="font-semibold">Tags (AND):</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {tags_and && tags_and.length > 0 ? (
            tags_and.join(", ")
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Tags (OR):</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {tags_or && tags_or.length > 0 ? (
            tags_or.join(", ")
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Metadata (AND):</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {metadata_and && metadata_and.length > 0 ? (
            metadata_and.map((item) => `${item.key}: ${item.value}`).join(", ")
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Metadata (OR):</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {metadata_or && metadata_or.length > 0 ? (
            metadata_or.map((item) => `${item.key}: ${item.value}`).join(", ")
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Scores:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {scores && scores.length > 0 ? (
            scores
              .map((item) => `${item.name} ${item.operator} ${item.value}`)
              .join(", ")
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Favorites:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {typeof starred === "boolean" ? (
            starred.toString()
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Search query:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {q ? q : <span className="italic text-gray-300">None</span>}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Start time:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {start_time ? (
            start_time.toLocaleString()
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">End time:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {end_time ? (
            end_time.toLocaleString()
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Limit:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {limit ? limit : <span className="italic text-gray-300">None</span>}
        </div>
        <div className="col-span-1">
          <span className="font-semibold">Prompt Template:</span>
        </div>
        <div className="col-span-3 text-gray-700">
          {prompt_template ? (
            `Name: ${prompt_template.name}, Versions: ${
              prompt_template.version_numbers
                ? prompt_template.version_numbers.join(", ")
                : "Not specified"
            }`
          ) : (
            <span className="italic text-gray-300">None</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UrlSearchParamsDisplay;
