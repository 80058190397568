import { Button, ButtonProps } from "@/components/ui/button";
import { useUser } from "@/context/user-context";
import { GitCompareIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CompareReportButton = (props: ButtonProps & { reportId?: number }) => {
  const navigate = useNavigate();
  const userContext = useUser();
  const { reportId, ...buttonProps } = props;
  return (
    <Button
      onClick={() =>
        navigate(
          reportId
            ? `/workspace/${userContext?.activeWorkspaceId}/evaluate/compare?report_id=${reportId}`
            : `/workspace/${userContext?.activeWorkspaceId}/evaluate/compare`,
        )
      }
      variant="outline"
      size="lg"
      {...buttonProps}
    >
      <GitCompareIcon className="mr-1 inline-block h-4 w-auto text-black" />
      Compare
    </Button>
  );
};

export default CompareReportButton;
