import { useAuth } from "@/context/auth-context";
import { useGetReportColumns } from "@/queries";
import { useMemo, useCallback } from "react";

interface ReportColumn {
  name: string;
  is_part_of_score: boolean;
  score: {
    value: string | number | boolean | null;
  } | null;
}

const useSubScoreTableData = (
  batchAId: number | undefined,
  batchBId: number | undefined,
) => {
  const auth = useAuth();
  const { isLoading: isLoadingReportA, data: reportAData } =
    useGetReportColumns(auth?.userToken!, batchAId ?? null);
  const { isLoading: isLoadingReportB, data: reportBData } =
    useGetReportColumns(auth?.userToken!, batchBId ?? null);

  const scoreAColumns = useMemo(
    () =>
      reportAData?.filter((column: ReportColumn) => column.is_part_of_score) ??
      [],
    [reportAData],
  );
  const scoreBColumns = useMemo(
    () =>
      reportBData?.filter((column: ReportColumn) => column.is_part_of_score) ??
      [],
    [reportBData],
  );

  const isLoading = isLoadingReportA || isLoadingReportB;
  const hasData = scoreAColumns.length > 0 || scoreBColumns.length > 0;

  const parseValue = useCallback(
    (value: string | number | boolean): number | boolean | string => {
      if (typeof value === "string") {
        const lowerValue = value.toLowerCase();
        if (lowerValue === "true") return true;
        if (lowerValue === "false") return false;
        const numValue = Number(value);
        if (!isNaN(numValue)) return numValue;
      }
      return value;
    },
    [],
  );

  const formatValue = useCallback(
    (col: ReportColumn | undefined): string | number | boolean => {
      if (!col?.score?.value) return "-";
      return parseValue(col.score.value);
    },
    [parseValue],
  );

  const columnOrder = useMemo(() => {
    const allColumns = [...scoreAColumns, ...scoreBColumns];
    return Array.from(new Set(allColumns.map((col) => col.name)));
  }, [scoreAColumns, scoreBColumns]);

  const createMatrixData = useCallback(
    (columns: ReportColumn[]) => {
      if (columns.length === 0) return [];

      const columnMap = new Map(columns.map((col) => [col.name, col]));
      return [
        ["Property", "Subscore"],
        ...columnOrder.map((name) => [name, formatValue(columnMap.get(name))]),
      ];
    },
    [columnOrder, formatValue],
  );

  const matrixDataA = useMemo(
    () => createMatrixData(scoreAColumns),
    [scoreAColumns, createMatrixData],
  );
  const matrixDataB = useMemo(
    () => createMatrixData(scoreBColumns),
    [scoreBColumns, createMatrixData],
  );

  return { isLoading, hasData, matrixDataA, matrixDataB };
};

export default useSubScoreTableData;
