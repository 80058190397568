import { ScoreCard, ScoreMatrixCellValue } from "../types";

export const isValidMatrix = (scorecard?: ScoreCard) => {
  if (!scorecard || scorecard.length === 0) return false;
  if (scorecard[0].length === 0) return false;
  if (scorecard.some((row) => !Array.isArray(row))) return false;
  if (
    scorecard
      .flat()
      .some(
        (score) =>
          typeof score !== "number" &&
          typeof score !== "string" &&
          !("ascending" in score || "value" in score),
      )
  )
    return false;
  return true;
};

export const parseCellValue = (cell: ScoreMatrixCellValue) => {
  if (typeof cell === "object") {
    return cell.value as number;
  }
  return cell as number;
};
