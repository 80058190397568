import { memo } from "react";
import { ScoreMatrixCellValue } from "../types";
import { parseCellValue } from "./utils";

const PercentageChange = memo(
  ({
    cell,
    compareCell,
  }: {
    cell: ScoreMatrixCellValue;
    compareCell?: ScoreMatrixCellValue;
  }) => {
    const valueChange =
      parseCellValue(cell) -
      ((compareCell && parseCellValue(compareCell)) || 0);
    const ascending = typeof cell === "object" ? cell.ascending ?? true : true;
    const isPositive = ascending ? valueChange > 0 : valueChange < 0;
    const displayValue = ascending ? valueChange : -valueChange;
    return (
      <span
        className={`ml-1 text-[10px] ${
          isPositive ? "text-green-600" : "text-red-600"
        }`}
      >
        {isPositive ? "+" : ""}
        {displayValue === Infinity ? "∞" : `${displayValue.toFixed(1)}`}
      </span>
    );
  },
);

export default PercentageChange;
