import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { FC } from "react";

const ToggleDetailsButton: FC<{
  showRuntimeDetails: boolean;
  onClick: () => void;
}> = ({ showRuntimeDetails, onClick }) => (
  <button
    className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline"
    onClick={onClick}
  >
    {showRuntimeDetails ? (
      <>
        Show less
        <ChevronUpIcon className="h-4 w-4" />
      </>
    ) : (
      <>
        Show more
        <ChevronDownIcon className="h-4 w-4" />
      </>
    )}
  </button>
);

export default ToggleDetailsButton;
