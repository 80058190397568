import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useGetReports } from "@/queries";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ComparisonBadge } from "./Badge";
import BatchSelector from "./BatchSelector";
import {
  CompareReportsProvider,
  getFormattedScore,
  useCompareReportsStore,
} from "./compare-reports-store";
import ComparisonResult from "./ComparisonResult";
import useDefaultReport from "./hooks/useDefaultReport";
import Scoreboard from "./Scoreboard";

const CompareReports = observer(() => {
  const reportStore = useCompareReportsStore();
  const {
    selectedBatchA,
    selectedBatchB,
    setSelectedBatch,
    isLoading,
    error,
    setLoading,
    setBatches,
    comparisonResult,
  } = reportStore;
  const userContext = useUser();
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState<number | undefined>(
    undefined,
  );
  const auth = useAuth();
  const { isLoading: isLoadingReports, data: reportData } = useGetReports(
    auth?.userToken!,
    userContext?.activeWorkspaceId,
    page,
    8,
    null,
  );
  useDefaultReport();

  React.useEffect(() => {
    if (!isLoadingReports && reportData) {
      setBatches(reportData.reports);
      setTotalPages(reportData.pages);
      setLoading(false);
    }
  }, [isLoading, isLoadingReports, reportData, setBatches, setLoading]);

  React.useEffect(() => {
    // generalize more
    setPage(1); // reset page on mount
  }, [reportStore, userContext.activeWorkspaceId]);

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="h-full w-full p-1">
      <Breadcrumbs
        items={["Evaluate", "Compare"]}
        navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
        pageTitle="Compare Reports"
        pageSubtitle="Compare evaluation results"
      />
      <div className="mt-4 overflow-auto rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
        <div className="mb-6 flex gap-4">
          <BatchSelector
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            label="Evaluation Run A"
            value={selectedBatchA}
            onChange={(batchId) => setSelectedBatch(Number(batchId), "A")}
          />
          {comparisonResult && (
            <ComparisonBadge
              percentageDifference={comparisonResult?.percentageDifference}
            />
          )}
          <BatchSelector
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            label="Evaluation Run B"
            value={selectedBatchB}
            onChange={(batchId) => setSelectedBatch(Number(batchId), "B")}
          />
        </div>
        {selectedBatchA &&
          selectedBatchB &&
          selectedBatchA?.score !== null &&
          selectedBatchB?.score !== null && (
            <Scoreboard
              score1={Number(getFormattedScore(selectedBatchA))}
              score2={Number(getFormattedScore(selectedBatchB))}
            />
          )}
        <div>
          {isLoading ? (
            <Skeleton className="h-64 w-full" />
          ) : (
            <ComparisonResult />
          )}
        </div>
      </div>
    </div>
  );
});

const CompareReportsHelper = () => {
  return (
    <CompareReportsProvider>
      <CompareReports />
    </CompareReportsProvider>
  );
};

export default CompareReportsHelper;
