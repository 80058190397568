import Matrix from "./Matrix";
import { isValidMatrix } from "./Matrix/utils";
import { ScoreData } from "./types";
import { getScoreColorClass } from "./utils";

interface MatrixWithLabelProps {
  scoreCard?: ScoreData["score_matrix"][0];
  compareCard?: ScoreData["score_matrix"][0];
  type: "before" | "after";
  showDiff: boolean;
  label?: string;
  showScore?: boolean;
  score?: number;
}

const MatrixWithLabel: React.FC<MatrixWithLabelProps> = ({
  scoreCard,
  compareCard,
  type,
  showDiff,
  label,
  showScore,
  score,
}) => {
  return (
    <div className="flex flex-col items-center">
      {label && <div className="mb-2 text-sm font-semibold">{label}</div>}
      {showScore && score !== undefined && (
        <div
          className={`mb-2 rounded-full px-3 py-1 text-sm font-semibold ${getScoreColorClass(
            score,
          )}`}
        >
          Score: {score.toFixed(2)}
        </div>
      )}
      <Matrix
        scoreCard={scoreCard}
        compareCard={(isValidMatrix(compareCard) && compareCard) || undefined}
        type={type}
        showDiff={showDiff}
      />
    </div>
  );
};

export default MatrixWithLabel;
