import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useForm } from "react-hook-form";

const ArchiveConfirmationForm = (props: {
  setOpen: (val: boolean) => void;
  onSuccess: () => void;
  name: string;
}) => {
  const form = useForm();
  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = async () => props.onSuccess();

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="prompt_name"
          render={({ field }) => (
            <FormItem>
              <FormDescription className="text-gray-700">
                This action cannot be undone.
              </FormDescription>
              <Input
                className="italic"
                placeholder={props.name}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setIsDisabled(e.target.value !== props.name);
                }}
              />
              <FormDescription>
                To confirm, type <b>`{props.name}`</b> in the box above.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-5">
          <Button
            type="button"
            onClick={() => props.setOpen(false)}
            className="bg-transparent text-sm"
            variant="outline"
          >
            Cancel
          </Button>
          <Button type="submit" variant="destructive" disabled={isDisabled}>
            Confirm Deletion
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default ArchiveConfirmationForm;
