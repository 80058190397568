import { useCallback, useEffect, useRef } from "react";
import { useContentArea } from "../../content-area-context";

const useCursorPosition = (ref: React.RefObject<HTMLDivElement>) => {
  const content = useContentArea();
  const lastValidSelectionRef = useRef<Range | null>(null);

  const setDefaultRange = (element: HTMLDivElement): Range => {
    const range = document.createRange();
    const firstChild = element.firstChild;
    if (firstChild) {
      range.setStart(firstChild, 0);
      range.setEnd(firstChild, 0);
    } else {
      range.setStart(element, 0);
      range.setEnd(element, 0);
    }
    return range;
  };

  const updateCursorPosition = useCallback(() => {
    if (!ref.current) {
      return;
    }

    try {
      const selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);

        // Check if selection is within our content area
        if (ref.current.contains(range.commonAncestorContainer)) {
          const clonedRange = range.cloneRange();
          lastValidSelectionRef.current = clonedRange;

          const rect = range.getBoundingClientRect();
          const containerRect = ref.current.getBoundingClientRect();

          // Set relative position
          const relativePosition = {
            x: rect.left,
            y: rect.top,
          };

          // Set absolute position within container
          const absolutePosition = {
            x: rect.left - containerRect.left + ref.current.offsetLeft,
            y: rect.top - containerRect.top + ref.current.offsetTop,
          };

          content.setSavedSelection(clonedRange);
          content.setCaretPosition(relativePosition);
          if (absolutePosition.x < 11 && absolutePosition.y < 8) {
            content.setAbsoluteCaretPosition({ x: 11, y: 8 });
          } else {
            content.setAbsoluteCaretPosition(absolutePosition);
          }

          return;
        }
      }

      // If we have a last valid selection, use that
      if (lastValidSelectionRef.current) {
        content.setSavedSelection(lastValidSelectionRef.current);
        return;
      }

      // Default to start of content
      const rect = ref.current.getBoundingClientRect();
      const defaultRange = document.createRange();
      const position = { x: rect.left + 11, y: rect.top + 8 };
      defaultRange.setStart(ref.current, 0);
      defaultRange.setEnd(ref.current, 0);
      defaultRange.collapse(true);
      lastValidSelectionRef.current = defaultRange;
      content.setSavedSelection(defaultRange);
      content.setCaretPosition(position);
      content.setAbsoluteCaretPosition({ x: 11, y: 8 });
    } catch (error) {
      // Fallback to start of content
      if (ref.current) {
        const defaultRange = setDefaultRange(ref.current);
        lastValidSelectionRef.current = defaultRange;
        content.setSavedSelection(defaultRange);
      }
    }
  }, [content, ref]);

  const handleSelectionChange = useCallback(() => {
    requestAnimationFrame(updateCursorPosition);
  }, [updateCursorPosition]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "a") {
        requestAnimationFrame(updateCursorPosition);
      }
      if (e.key === "Delete" || e.key === "Backspace") {
        requestAnimationFrame(updateCursorPosition);
      }
    },
    [updateCursorPosition],
  );

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    element.addEventListener("keyup", updateCursorPosition);
    element.addEventListener("keydown", handleKeyDown);
    element.addEventListener("mouseup", updateCursorPosition);
    document.addEventListener("selectionchange", handleSelectionChange);

    // Set initial cursor position
    updateCursorPosition();

    return () => {
      element.removeEventListener("keyup", updateCursorPosition);
      element.removeEventListener("keydown", handleKeyDown);
      element.removeEventListener("mouseup", updateCursorPosition);
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [ref, updateCursorPosition, handleSelectionChange, handleKeyDown]);

  return updateCursorPosition;
};

export default useCursorPosition;
