import UrlSearchParamsDisplay from "@/components/UrlSearchParamsDisplay";
import { Button } from "@/components/ui/button";
import { DatasetRead } from "@/types/datasets";
import { Menu, Transition } from "@headlessui/react";
import { EyeIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";

const FilterParamsButton = ({
  activeDataset,
}: {
  activeDataset?: DatasetRead;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const {
    end_time,
    limit,
    metadata_and,
    metadata_or,
    prompt_template,
    q,
    scores,
    starred,
    start_time,
    tags_and,
    tags_or,
  } = activeDataset?.filter_params || {};

  const hasFiltersSet = [
    end_time,
    limit,
    metadata_and,
    metadata_or,
    prompt_template,
    q,
    scores,
    starred,
    start_time,
    tags_and,
    tags_or,
  ].some((item: any) => {
    const isNotNullOrUndefined = ![null, undefined].includes(item);
    const isNonEmptyArray = Array.isArray(item) ? item.length > 0 : true;
    return isNotNullOrUndefined && isNonEmptyArray;
  });

  if (!hasFiltersSet) return null;

  return (
    <Menu as="div" className="relative z-20">
      <Menu.Button
        as={Button}
        onClick={toggleDropdown}
        size="sm"
        variant="outline"
      >
        <EyeIcon className="mr-1 inline-block h-4 w-4 text-gray-600" />
        Filter Params
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-50 mt-2 w-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="rounded-sm border border-gray-200 bg-gray-100 px-4">
            <UrlSearchParamsDisplay
              end_time={activeDataset?.filter_params?.end_time}
              limit={activeDataset?.filter_params?.limit}
              metadata_and={activeDataset?.filter_params?.metadata_and}
              metadata_or={activeDataset?.filter_params?.metadata_or}
              prompt_template={activeDataset?.filter_params?.prompt_template}
              q={activeDataset?.filter_params?.q}
              scores={activeDataset?.filter_params?.scores}
              starred={activeDataset?.filter_params?.starred}
              start_time={activeDataset?.filter_params?.start_time}
              tags_and={activeDataset?.filter_params?.tags_and}
              tags_or={activeDataset?.filter_params?.tags_or}
              title="Filter Params"
            />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default FilterParamsButton;
