import { PromptVersionSnippet } from "@/types/apiGetters";
import { action, makeAutoObservable, runInAction } from "mobx";
import { unescapeXml } from "./utils";

interface CustomComponent {
  id: string;
  type: string;
  data: any;
}
class TextAreaStore {
  content: string = "";
  textContent: string = "";
  html: string = "";
  savedSelection: Range | null = null;
  customComponents: CustomComponent[] = [];
  widgets: Record<string, string> = {}; // Widget Id, Widget Value
  showContextMenu: boolean = false;
  contextMenuPosition: { top: number; left: number } = {
    top: 0,
    left: 0,
  };
  lastSlashPosition: number = 0;
  sourcedSnippets?: Array<PromptVersionSnippet>;
  caretPos: { x: number; y: number } = { x: 0, y: 0 };
  absCaretPos: { x: number; y: number } = { x: 0, y: 0 };

  constructor(
    initialValue: string,
    sourcedSnippets?: Array<PromptVersionSnippet>,
  ) {
    this.html = initialValue;
    this.sourcedSnippets = sourcedSnippets;
    makeAutoObservable(this);
  }

  @action
  setAbsoluteCaretPosition(position: { x: number; y: number }): void {
    this.absCaretPos = position;
  }

  get absoluteCaretPosition() {
    return this.absCaretPos;
  }

  @action
  setShowContextMenu = (showContextMenu: boolean): void => {
    this.showContextMenu = showContextMenu;
  };

  @action setContextMenuPosition = (contextMenuPosition: {
    top: number;
    left: number;
  }): void => {
    this.contextMenuPosition = contextMenuPosition;
  };

  @action setCaretPosition(position: { x: number; y: number }): void {
    this.caretPos = position;
  }

  get caretPosition() {
    if (
      this.savedSelectionPosition.x === 0 &&
      this.savedSelectionPosition.y === 0
    ) {
      return this.caretPos;
    }
    return this.savedSelectionPosition;
  }
  get isCurrentLineEmpty(): boolean {
    try {
      if (!this.savedSelection) return false;

      const selection = this.savedSelection;
      if (!selection) return false;

      const range = selection.cloneRange();
      const node = range.startContainer;
      if (!node || !node.textContent) return true;

      const offset = range.startOffset;

      // Get text content before and after caret
      const textContent = node.textContent;
      const textBefore = textContent.substring(0, offset).trim();
      const textAfter = textContent.substring(offset).trim();

      // Check if caret is at end of line after a section element
      const parentNode = node.parentElement;
      if (parentNode) {
        const prevSibling = node.previousSibling;
        if (prevSibling && prevSibling.nodeName === "SECTION" && offset === 0) {
          return false;
        }
      }

      return textBefore === "" && textAfter === "";
    } catch (error) {
      console.error("Error checking if current line is empty:", error);
      return false;
    }
  }

  @action setSavedSelection = (savedSelection: Range): void => {
    this.savedSelection = savedSelection;
  };

  get savedSelectionPosition() {
    if (this.savedSelection) {
      const rect = this.savedSelection.getBoundingClientRect();
      return { x: rect.left, y: rect.top };
    }
    return { x: 0, y: 0 };
  }

  getContent() {
    return this.content;
  }

  getWidgetValue(id: string) {
    return this.widgets[id];
  }

  @action
  setWidget = (id: string, value: string): void => {
    runInAction(() => {
      this.widgets[id] = value;
      this.setContent(this.html); // re-render content
    });
  };

  @action
  removeWidget = (id: string): void => {
    runInAction(() => {
      delete this.widgets[id];
      this.setContent(this.html); // re-render content
    });
  };

  @action
  setContent(innerHTML: string) {
    this.html = innerHTML;
    // Replace custom components with placeholder
    innerHTML = innerHTML.replace(
      /<section[^>]*data-custom-component-id="([^"]+)"[^>]*>[\s\S]*?<\/section>/g,
      (_, id) => {
        return this.getWidgetValue(id) ?? "";
      },
    );

    // Handle all possible line break scenarios in contenteditable div
    const parsedContent = unescapeXml(
      innerHTML
        .replaceAll(/<div><br\s*\/?><\/div>/gi, "\n")
        .replaceAll(/<br\s*\/?>/gi, "\n")
        //.replaceAll(/<\/div[\s]*>/gi, "\n")
        .replaceAll(/<div[\s]*>/gi, "\n")
        .replace(/<div[\s]*>/gi, "")
        .replace(/<\/div[\s]*>/gi, "")
        .replace(/&nbsp;/g, " "),
    );

    this.content = parsedContent;
  }

  logContent() {
    const content = this.getContent();
    console.log("Content:", content);
  }

  addCustomComponent(component: CustomComponent) {
    this.customComponents.push(component);
  }

  removeCustomComponent(id: string) {
    this.customComponents = this.customComponents.filter(
      (comp) => comp.id !== id,
    );
  }

  getCustomComponentById(id: string) {
    return this.customComponents.find((comp) => comp.id === id);
  }

  serialize(): string {
    const serializedComponents = this.customComponents.map(
      (comp) =>
        `[CustomComponent:${comp.id}:${comp.type}:${JSON.stringify(
          comp.data,
        )}]`,
    );

    const contentWithoutHtml = this.content.replace(/<[^>]*>/g, "");
    return contentWithoutHtml + serializedComponents.join("");
  }

  deserialize(serializedContent: string): void {
    const regex = /\[CustomComponent:([^:]+):([^:]+):({.*?})\]/g;
    let match;
    const components: CustomComponent[] = [];
    let content = serializedContent;

    while ((match = regex.exec(serializedContent)) !== null) {
      const [fullMatch, id, type, dataString] = match;
      const data = JSON.parse(dataString);
      components.push({ id, type, data });
      content = content.replace(fullMatch, "\u200B");
    }

    this.setContent(content);
    this.customComponents = components;
  }
}

export default TextAreaStore;

export type ITextAreaStore = InstanceType<typeof TextAreaStore>;
