import { Skeleton } from "@/components/ui/skeleton";

import { observer } from "mobx-react-lite";
import ScoreMatrix from "../ScoreMatrix";
import { ScoreCard } from "../ScoreMatrix/types";
import { useCompareReportsStore } from "./compare-reports-store";
import useSubScoreTableData from "./hooks/useSubScoreTableData";

const ComparisonResult = observer(() => {
  const { comparisonResult, isLoading, error, selectedBatchA, selectedBatchB } =
    useCompareReportsStore();

  const {
    isLoading: isLoadingSubScoreTable,
    hasData: hasSubScoreTableData,
    matrixDataA,
    matrixDataB,
  } = useSubScoreTableData(selectedBatchA?.id, selectedBatchB?.id);

  if (isLoading || isLoadingSubScoreTable) {
    return (
      <div className="flex justify-center gap-4">
        <Skeleton className="h-40 w-full max-w-[400px] " />
        <Skeleton className="h-40 w-full max-w-[400px] " />
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (!selectedBatchA || !selectedBatchB) {
    return (
      <p className="text-center text-gray-500">
        Select both batches to see comparison result.
      </p>
    );
  }

  if (!comparisonResult) {
    return (
      <p className="text-center text-gray-500">Invalid Batch Comparison</p>
    );
  }

  return (
    <>
      <div className="flex justify-center gap-4">
        {selectedBatchA?.score_matrix ||
        selectedBatchB?.score_matrix ||
        hasSubScoreTableData ? (
          <ScoreMatrix
            beforeScore={
              selectedBatchA?.score_matrix || matrixDataA.length > 0
                ? {
                    score_matrix: (selectedBatchA?.score_matrix || [
                      matrixDataA,
                    ]) as ScoreCard[],
                    score: 0,
                  }
                : undefined
            }
            showAll
            afterScore={
              selectedBatchB?.score_matrix || matrixDataB.length > 0
                ? {
                    score_matrix: (selectedBatchB?.score_matrix || [
                      matrixDataB,
                    ]) as ScoreCard[],
                    score: 0,
                  }
                : undefined
            }
          />
        ) : null}
      </div>
    </>
  );
});

export default ComparisonResult;
