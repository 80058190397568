import { useState, useCallback } from "react";

const useValidation = (name: string, code: string) => {
  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    code: boolean;
  }>({
    name: true,
    code: true,
  });

  const validate = useCallback(() => {
    if (!name.trim()) {
      setDataIsValid({ name: false, code: true });
      return false;
    } else if (!code.trim()) {
      setDataIsValid({ name: true, code: false });
      return false;
    }
    setDataIsValid({ name: true, code: true });
    return true;
  }, [name, code]);

  return { dataIsValid, validate };
};

export default useValidation;
