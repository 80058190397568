import { useMemo } from "react";
import useDiffStyles from "../hooks/useDiffStyles";
import { DiffState, ScoreMatrixCellValue } from "../types";
import PercentageChange from "./PercentageChange";
import { parseCellValue } from "./utils";

const MatrixCell = ({
  cell,
  compareCell,
  type,
  cellIndex,
  diffState,
  isFirstColumn,
}: {
  cell: ScoreMatrixCellValue;
  compareCell: ScoreMatrixCellValue | undefined;
  type: string;
  cellIndex: number;
  diffState: DiffState;
  isFirstColumn: boolean;
}) => {
  const { getDiffStyle } = useDiffStyles();

  const showPercentage = useMemo(
    () =>
      diffState !== null &&
      typeof parseCellValue(cell) === "number" &&
      compareCell !== undefined &&
      typeof parseCellValue(compareCell) === "number",
    [diffState, cell, compareCell],
  );

  const hasRowHeaders = isFirstColumn && !showPercentage;

  return (
    <div
      key={`${type}-cell-${cellIndex}`}
      className={`max-h-[76px] overflow-auto rounded p-2 text-center text-xs transition-all ease-in-out ${getDiffStyle(
        diffState,
      )} ${isFirstColumn ? "text-left" : ""}`}
    >
      <div
        className={`flex flex-col ${
          hasRowHeaders ? "items-start" : "items-center"
        } justify-center`}
      >
        <span className={"w-full break-words"}>
          {typeof cell === "object" ? cell.value : cell}
        </span>
        {showPercentage && (
          <PercentageChange cell={cell} compareCell={compareCell} />
        )}
      </div>
    </div>
  );
};

export default MatrixCell;
