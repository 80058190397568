import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  CodeExecutionConfiguration,
  LanguageType,
  ReportColumn,
} from "@/types/evaluate";

import { useCallback, useMemo, useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";
import CodeEditor from "./CodeEditor";
import useColumnLanguage from "./hooks/useColumnLanguage";
import useMonacoCompletion from "./hooks/useMonacoCompletion";
import useValidation from "./hooks/useValidation";

const languages = [
  { value: "PYTHON" as const, label: "Python" },
  { value: "JAVASCRIPT" as const, label: "JavaScript" },
] as const;

interface CodeColumnBuilderProps {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}

const CodeColumnBuilder: React.FC<CodeColumnBuilderProps> = ({
  columnData,
  navigatePrevious,
  saveColumnAction,
  editable,
  availableColumns,
}) => {
  const [name, setName] = useState<string>(columnData.name || "");

  const columnNamesInScope = useMemo(
    () =>
      availableColumns
        .filter((column) => column.name !== columnData.name)
        .map((column) => column.name),
    [availableColumns, columnData.name],
  );

  const { language, code, setCode, handleLanguageChange } =
    useColumnLanguage(columnData);
  useMonacoCompletion(language, columnNamesInScope);
  const { dataIsValid, validate } = useValidation(name, code);

  const saveEndpointAction = useCallback(() => {
    if (!validate()) return;

    saveColumnAction({
      ...columnData,
      name: name.trim(),
      configuration: {
        code: code.trim(),
        language,
      } as CodeExecutionConfiguration,
    } as ReportColumn);
  }, [columnData, name, code, language, saveColumnAction, validate]);

  const bandaid = useBandaid();

  const editorOptions = useMemo(
    () => ({
      readOnly: !editable,
    }),
    [editable],
  );

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Code Execution</div>
          <div className="max-w-md text-sm text-gray-500">
            {bandaid
              ? "Write code that will execute when this node is triggered. Access data through the `data` variable. Return the transformed value. Stdout will be ignored."
              : "Write code that will execute for each row. Access data through the `data` variable. Return the cell value. Stdout will be ignored."}
          </div>
        </div>
        <label
          htmlFor="name"
          className="col-span-1"
          style={{ display: bandaid ? "none" : "" }}
        >
          {bandaid ? "Node" : "Column"} name:
        </label>
        <input
          id="column-name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-200"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
          style={{ display: bandaid ? "none" : "" }}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}
        <>
          <div className="col-span-1">Language:</div>
          <div className="col-span-2">
            <Select
              disabled={!editable}
              onValueChange={(value) =>
                handleLanguageChange(value as LanguageType)
              }
              value={language}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a language..." />
              </SelectTrigger>
              <SelectContent>
                {languages.map((lang) => (
                  <SelectItem key={lang.value} value={lang.value}>
                    {lang.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </>
        <CodeEditor
          language={language}
          code={code}
          setCode={setCode}
          editorOptions={editorOptions}
          dataIsValid={dataIsValid}
        />
      </div>
    </ModalStep>
  );
};

export default CodeColumnBuilder;
