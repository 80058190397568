import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { WorkflowNode } from "@/components/Workflows/types";
import { useAuth } from "@/context/auth-context";
import { useCreateReportColumn, usePatchReportColumn } from "@/queries";
import { ValidationError } from "@/types/errors";
import { ReportColumn } from "@/types/evaluate";
import { ExclamationIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import { forwardRef, ReactNode, useEffect, useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import AssertValidBuilder from "./AssertionType/AssertValidBuilder";
import LLMAssertionBuilder from "./AssertionType/LLMAssertionBuilder";
import { ChooseColumnType } from "./ChooseColumnType";
import CoalesceColumnBuilder from "./CoalesceType/CoalesceColumnBuilder";
import CodeColumnBuilder from "./CodeType/CodeColumnBuilder";
import CombineColumnsBuilder from "./CombineColumnsType";
import CompareColumnBuilder from "./CompareType/CompareColumnBuilder";
import EndpointColumnBuilder from "./EndpointType/EndpointColumnBuilder";
import HumanInputColumnBuilder from "./HumanInputType/HumanInputColumnBuilder";
import JSONPathBuilder from "./JSONType/JsonPathBuilder";
import AbsoluteNumericDistanceBuilder from "./MathType/AbsoluteNumericDistance";
import CosineSimilarityBuilder from "./MathType/CosineSimilarityBuilder";
import CountBuilder from "./MathType/CountBuilder";
import MathOperatorBuilder from "./MathType/OperatorBuilder";
import MinMaxBuilder from "./MinMaxColumnType";
import PromptTemplateColumnBuilder from "./PromptTemplateType/PromptTemplateColumnBuilder";
import RegexExtractionBuilder from "./RegexExtractionType";
import RegexBuilder from "./RegexType";
import ContainsBuilder from "./StringManipulationType/ContainsBuilder";
import ParseValueBuilder from "./StringManipulationType/ParseValueBuilder";
import VariableBuilder from "./VariableType";
import ViewOnlyBanner from "./ViewOnlyBanner";
import WorkflowColumnBuilder from "./WorkflowColumnType";
import XMLPathBuilder from "./XMLPathBuilder";

export const ModalStep = ({
  children,
  navigateNext,
  navigatePrevious,
  nextButtonText = "Next",
  previousButtonText = "Previous",
}: {
  children: ReactNode;
  navigateNext?: () => void;
  navigatePrevious?: () => void;
  nextButtonText?: string;
  previousButtonText?: string;
}) => {
  const hideButtons = useBandaid();

  if (hideButtons && hideButtons.readonly) return <>{children}</>;

  if (
    hideButtons &&
    (children as any)?.props?.children?.props &&
    !(children as any)?.props?.children?.props?.columnName &&
    nextButtonText.toLowerCase().includes("save step")
  )
    return <>{children}</>;
  return (
    <div>
      {children}
      <div className="mt-4 flex justify-end gap-x-2">
        {
          <>
            {hideButtons ? (
              <>
                {previousButtonText.toLowerCase().includes("save") &&
                  navigatePrevious && (
                    <Button variant="outline" onClick={navigatePrevious}>
                      {previousButtonText}
                    </Button>
                  )}
                {nextButtonText.toLowerCase().includes("save") &&
                  navigateNext && (
                    <Button onClick={navigateNext}>{nextButtonText}</Button>
                  )}
              </>
            ) : (
              <>
                {navigatePrevious && (
                  <Button variant="outline" onClick={navigatePrevious}>
                    {previousButtonText}
                  </Button>
                )}
                {navigateNext && (
                  <Button onClick={navigateNext}>{nextButtonText}</Button>
                )}
              </>
            )}
          </>
        }
      </div>
    </div>
  );
};

export const ModalContent = observer(
  ({
    currentModalNavigation,
    newColumnData,
    setCurrentModalNavigation,
    patchColumnData,
    editable,
    availableColumns,
    saveColumnAction,
    setOpen,
  }: {
    currentModalNavigation: string;
    newColumnData: Partial<ReportColumn & WorkflowNode>;
    setCurrentModalNavigation: (step: string) => void;
    patchColumnData: (
      data:
        | Partial<ReportColumn>
        | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
    ) => void;
    editable: boolean;
    availableColumns: ReportColumn[];
    saveColumnAction: (newColumnDataArg: ReportColumn) => void;
    setOpen: (open: boolean) => void;
  }) => {
    const modalNavigation = {
      "choose-type": (
        <ModalStep
          navigateNext={
            newColumnData.column_type
              ? () => setCurrentModalNavigation("setup-column")
              : undefined
          }
        >
          <ChooseColumnType
            patchColumnData={patchColumnData}
            columnData={newColumnData}
            editable={editable}
          />
        </ModalStep>
      ),
      "setup-column": (() => {
        const { column_type, node_type } = newColumnData;
        switch (column_type || node_type) {
          case "PROMPT_TEMPLATE":
            return (
              <PromptTemplateColumnBuilder
                columnData={newColumnData}
                patchColumnData={patchColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "ENDPOINT":
            return (
              <EndpointColumnBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "HUMAN":
            return (
              <HumanInputColumnBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "COMPARE":
            return (
              <CompareColumnBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "COALESCE":
            return (
              <CoalesceColumnBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "ABSOLUTE_NUMERIC_DISTANCE":
            return (
              <AbsoluteNumericDistanceBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "REGEX":
            return (
              <RegexBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "JSON_PATH":
            return (
              <JSONPathBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "PARSE_VALUE":
            return (
              <ParseValueBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "CONTAINS":
            return (
              <ContainsBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "LLM_ASSERTION":
            return (
              <LLMAssertionBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "VARIABLE":
            return (
              <VariableBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "ASSERT_VALID":
            return (
              <AssertValidBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "COSINE_SIMILARITY":
            return (
              <CosineSimilarityBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "COUNT":
            return (
              <CountBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "MATH_OPERATOR":
            return (
              <MathOperatorBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "XML_PATH":
            return (
              <XMLPathBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "REGEX_EXTRACTION":
            return (
              <RegexExtractionBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "COMBINE_COLUMNS":
            return (
              <CombineColumnsBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "MIN_MAX":
            return (
              <MinMaxBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "CODE_EXECUTION":
            return (
              <CodeColumnBuilder
                availableColumns={availableColumns}
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                editable={editable}
              />
            );
          case "WORKFLOW":
            return (
              <WorkflowColumnBuilder
                columnData={newColumnData}
                navigatePrevious={() =>
                  setCurrentModalNavigation("choose-type")
                }
                availableColumns={availableColumns}
                saveColumnAction={
                  editable ? saveColumnAction : () => setOpen(false)
                }
                patchColumnData={patchColumnData}
                editable={editable}
              />
            );
          default:
            return null;
        }
      })(),
    };

    return (
      modalNavigation[currentModalNavigation as keyof typeof modalNavigation] ||
      null
    );
  },
);

const ModalRouter = forwardRef<
  HTMLDivElement,
  {
    availableColumns: ReportColumn[];
    reportId: number;
    oldColumn?: ReportColumn;
    editable?: boolean;
    open: boolean;
    onOpenChange: (open: boolean) => void;
  }
>(
  (
    {
      availableColumns,
      reportId,
      oldColumn,
      editable = true,
      open,
      onOpenChange,
    },
    ref,
  ) => {
    return (
      <ModalRouterHelper
        key={String(open)}
        reportId={reportId}
        open={open}
        setOpen={onOpenChange}
        availableColumns={availableColumns}
        oldColumn={oldColumn}
        editable={editable}
        ref={ref}
      />
    );
  },
);

const ModalRouterHelper = forwardRef<
  HTMLDivElement,
  {
    availableColumns: ReportColumn[];
    reportId: number;
    oldColumn?: ReportColumn;
    open: boolean;
    setOpen: (open: boolean) => void;
    editable: boolean;
  }
>(({ availableColumns, reportId, oldColumn, open, setOpen, editable }, ref) => {
  const FIRST_MODAL_STEP = oldColumn ? "setup-column" : "choose-type";
  const [currentModalNavigation, setCurrentModalNavigation] =
    useState(FIRST_MODAL_STEP);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const userToken = useAuth()?.userToken;
  const {
    mutate: createReportColumn,
    data: createReportColumnData,
    isLoading: createReportColumnIsLoading,
    error: createReportColumnError,
  } = useCreateReportColumn(userToken || "", reportId);
  const {
    mutate: patchReportColumn,
    data: patchData,
    isLoading: patchIsLoading,
    error: patchError,
  } = usePatchReportColumn(userToken || "", reportId);
  const data = oldColumn ? patchData : createReportColumnData;
  const isLoading = createReportColumnIsLoading || patchIsLoading;
  const errorData: { message?: ValidationError[]; success?: boolean } | null =
    createReportColumnError || patchError || null;

  const [newColumnData, setNewColumnData] = useState<Partial<ReportColumn>>(
    oldColumn || {},
  );
  const patchColumnData = (
    data:
      | Partial<ReportColumn>
      | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
  ) => {
    setNewColumnData((prevState) => {
      if (typeof data === "function") {
        return {
          ...prevState,
          ...data(prevState),
        };
      } else {
        return {
          ...prevState,
          ...data,
        };
      }
    });
  };

  const saveColumnAction = (newColumnDataArg: ReportColumn) => {
    const newColumnDataBuilder: ReportColumn = { ...newColumnDataArg };
    if (!oldColumn) {
      // Creating column from scratch, we need to add a few things
      const maxPosition =
        availableColumns.length > 0
          ? Math.max(...availableColumns.map((c) => c.position || 0))
          : 0;
      newColumnDataBuilder.position = maxPosition + 1;
      newColumnDataBuilder.report_id = reportId;
      if (newColumnDataBuilder as ReportColumn) {
        createReportColumn(newColumnDataBuilder as ReportColumn);
      } else {
        console.error("Error: Invalid step data type");
      }
    } else if (newColumnDataBuilder) {
      patchReportColumn(newColumnDataBuilder);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      if (data.success) {
        setOpen(false);
      } else {
        if (data.message && data.message[0]) {
          if (data.message[0].loc && data.message[0].loc[0]) {
            setErrorMessage(
              `Error: ${data.message[0].loc[0]} (${data.message[0].msg})`,
            );
          } else {
            setErrorMessage(`Error: ${data.message[0].msg}`);
          }
        } else {
          setErrorMessage("Error: Unknown error occurred");
        }
      }
    }
    if (!isLoading && errorData && errorData.message) {
      if (errorData.message[0] && errorData.message[0].loc) {
        if (errorData.message[0].loc[0]) {
          setErrorMessage(
            `Error: ${errorData.message[0].loc[0]} (${errorData.message[0].msg})`,
          );
        } else {
          setErrorMessage(`Error: ${errorData.message[0].msg}`);
        }
      } else {
        setErrorMessage("Error: Unknown error occurred");
      }
    }
  }, [isLoading, data, setOpen, errorData]);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      setCurrentModalNavigation(FIRST_MODAL_STEP);
      setNewColumnData({});
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent className="max-h-screen overflow-auto" ref={ref}>
        <div className="p-2">
          {!editable && <ViewOnlyBanner />}
          <ModalContent
            currentModalNavigation={currentModalNavigation}
            newColumnData={
              newColumnData as Partial<ReportColumn & WorkflowNode>
            }
            setCurrentModalNavigation={setCurrentModalNavigation}
            patchColumnData={patchColumnData}
            editable={editable}
            availableColumns={availableColumns}
            saveColumnAction={saveColumnAction}
            setOpen={setOpen}
          />
          {errorMessage && (
            <div className="w-34 mx-auto mt-4 rounded-md bg-red-100 p-2 text-xs text-red-600">
              <ExclamationIcon className="mr-1 inline-block h-4 w-4" />
              {errorMessage}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
});

export default ModalRouter;
