import { useReportContext } from "@/components/Evaluate/report-context";
import { ColumnType, ReportColumn } from "@/types/evaluate";
import { action, makeAutoObservable, runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import { createContext, useContext, useEffect } from "react";

class ColumnStateStore {
  columnStates: { [key: string]: boolean } = {};
  columns: ReportColumn[] = [];
  storageKey: string = "";

  constructor(columns: ReportColumn[]) {
    makeAutoObservable(this, {
      initializeColumnStates: action,
      toggleColumn: action,
      showAllColumns: action,
      saveToLocalStorage: action,
    });

    if (!columns || columns.length === 0) {
      return;
    }

    this.columns = columns;
    const reportId = columns[0]?.report_id;
    if (!reportId) {
      return;
    }

    this.storageKey = `collapsedColumns_${reportId}`;
    this.initializeColumnStates();
  }

  @action
  setColumns(columns: ReportColumn[]) {
    this.columns = columns;
    this.storageKey = `collapsedColumns_${columns[0]?.report_id}`;
    this.initializeColumnStates();
  }

  @action
  initializeColumnStates() {
    if (!this.storageKey || !this.columns.length) {
      return;
    }

    const storedStates = localStorage.getItem(this.storageKey);
    if (storedStates) {
      try {
        const parsed = JSON.parse(storedStates);
        runInAction(() => {
          this.columnStates = parsed;
        });
      } catch (e) {
        console.error("Failed to parse stored column states");
        this.setDefaultColumnStates();
      }
    } else {
      this.setDefaultColumnStates();
    }
  }

  private setDefaultColumnStates() {
    runInAction(() => {
      this.columnStates = this.columns.reduce(
        (
          acc: { [key: string]: boolean },
          column: ReportColumn,
          index: number,
        ) => {
          if (column?.name !== undefined) {
            acc[column.name] =
              column.column_type === ColumnType.DATASET &&
              this.columns.length > 5 &&
              index < this.columns.length - 5;
          }
          return acc;
        },
        {},
      );
    });
  }

  isColumnCollapsed = (columnName: string): boolean => {
    return this.columnStates[columnName] === true;
  };

  toggleColumn = (columnName?: string): void => {
    if (!columnName) return;
    runInAction(() => {
      this.columnStates[columnName] = !this.columnStates[columnName];
    });
    this.saveToLocalStorage();
  };

  showAllColumns = (): void => {
    runInAction(() => {
      this.columnStates = {};
    });
    this.saveToLocalStorage();
  };

  get totalHiddenColumns(): number {
    return this.columns.reduce((acc: number, column: ReportColumn) => {
      if (
        column?.name !== undefined &&
        this.columnStates[column.name] === true
      ) {
        acc++;
      }
      return acc;
    }, 0);
  }

  saveToLocalStorage(): void {
    if (!this.storageKey) return;
    localStorage.setItem(this.storageKey, JSON.stringify(this.columnStates));
  }
}

export const ColumnStateContext = createContext<ColumnStateStore | null>(null);

export const useCollapsibleColumnContext = () => {
  const context = useContext(ColumnStateContext);
  if (!context) {
    throw new Error(
      "useCollapsibleColumnContext must be used within a ColumnStateProvider",
    );
  }
  return context;
};

const useCollapsibleColumns = (columns: ReportColumn[]) => {
  const report = useReportContext();
  const parentReportId = report?.reportMetadata?.parent_report_id;
  const store = useContext(ColumnStateContext);

  useEffect(() => {
    if (store && columns.length >= 0 && columns !== store.columns)
      store.setColumns(
        columns.map((column) => ({
          ...column,
          report_id: parentReportId ?? column.report_id,
        })),
      );
  }, [columns, parentReportId, store]);

  return store;
};

export const ColumnStateProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const report = useReportContext();
    const columns = report?.sortedColumns;
    const parentReportId = report?.reportMetadata?.parent_report_id;
    const store = useLocalObservable(
      () =>
        new ColumnStateStore(
          columns.map((column: ReportColumn) => ({
            ...column,
            report_id: parentReportId ?? column.report_id,
          })),
        ),
      [columns],
    );
    return (
      <ColumnStateContext.Provider value={store}>
        {children}
      </ColumnStateContext.Provider>
    );
  },
);

export default useCollapsibleColumns;
