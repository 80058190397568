import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { ColumnType, ColumnTypeIcon } from "@/types/evaluate";
import { useReactFlow } from "@xyflow/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { WorkflowNodeType } from "../../types";
import { formatRawNodeType } from "../../utils";
import { useWorkflow } from "../workflow-context";

interface OptionType {
  type: ColumnType;
  description?: string;
}

// Categorize options with descriptions
const categories: Record<string, OptionType[]> = {
  "Data Sources": [
    {
      type: ColumnType.PROMPT_TEMPLATE,
      description: "Run a prompt through an LLM.",
    },
    {
      type: ColumnType.ENDPOINT,
      description: "Send data to your URL endpoint.",
    },
    {
      type: ColumnType.CODE_EXECUTION,
      description: "Execute Python or Javscript.",
    },
    {
      type: ColumnType.WORKFLOW,
      description: "Run another workflow.",
    },
  ],
  "Simple Evals": [
    { type: ColumnType.COMPARE },
    { type: ColumnType.ABSOLUTE_NUMERIC_DISTANCE },
    { type: ColumnType.CONTAINS },
    { type: ColumnType.ASSERT_VALID },
    { type: ColumnType.COUNT },
    { type: ColumnType.MIN_MAX },
  ],
  "LLM Evals": [
    { type: ColumnType.LLM_ASSERTION },
    { type: ColumnType.COSINE_SIMILARITY },
  ],
  "Helper Functions": [
    { type: ColumnType.COALESCE },
    { type: ColumnType.REGEX },
    { type: ColumnType.JSON_PATH },
    { type: ColumnType.PARSE_VALUE },
    { type: ColumnType.VARIABLE },
    { type: ColumnType.XML_PATH },
    { type: ColumnType.REGEX_EXTRACTION },
    { type: ColumnType.COMBINE_COLUMNS },
    { type: ColumnType.MATH_OPERATOR },
  ],
};

interface NodeTypeSelectorProps {
  readonly?: boolean;
}

const NodeTypeSelector = (props: NodeTypeSelectorProps) => {
  const { readonly } = props;
  const { activeNode } = useWorkflow();
  const { updateNode } = useReactFlow();
  if (!activeNode) return null;

  const onSelect = (value: ColumnType) => {
    if (!activeNode || readonly) return;

    runInAction(() =>
      updateNode(activeNode.id, (node) => ({
        ...node,
        type: "basic",
        data: {
          type: value as unknown as WorkflowNodeType,
          label: activeNode.name,
          unsaved: true,
        },
      })),
    );
  };

  const currentType = activeNode.node_type;
  const SelectedTypeIcon =
    currentType &&
    (ColumnTypeIcon[currentType as unknown as ColumnType] as React.ElementType);

  const renderMenuItem = (option: OptionType) => {
    const TypeIcon = ColumnTypeIcon[option.type] as React.ElementType;

    return (
      <DropdownMenuItem
        key={option.type}
        onSelect={() => onSelect(option.type)}
        className="flex w-full items-center space-x-2 px-3 py-2 transition-colors duration-200 hover:bg-gray-100"
      >
        <TypeIcon className="h-5 w-5 flex-shrink-0" />
        <div className="flex flex-col">
          <span className="font-medium">{formatRawNodeType(option.type)}</span>
          {option.description && (
            <span className="mt-1 text-xs text-gray-500">
              {option.description}
            </span>
          )}
        </div>
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu open={readonly ? false : undefined}>
      <DropdownMenuTrigger
        disabled={readonly}
        className="mb-4 rounded-md border px-4 py-2 transition-colors duration-200 hover:bg-gray-100"
      >
        <div className="inline-flex items-center space-x-2">
          {SelectedTypeIcon && <SelectedTypeIcon className="h-5 w-5" />}
          <span>{formatRawNodeType(currentType)}</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {Object.entries(categories).map(([category, types]) => (
          <div key={category}>
            <DropdownMenuLabel className="text-xs text-gray-500">
              {category}
            </DropdownMenuLabel>
            {types.map(renderMenuItem)}
            <DropdownMenuSeparator />
          </div>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default observer(NodeTypeSelector);
