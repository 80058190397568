import { Switch as HeadlessSwitch, SwitchProps } from "@headlessui/react";

interface LockableSwitchProps extends SwitchProps<"input"> {
  lockedState?: boolean | null;
  disabled?: boolean;
}

export const Switch = ({
  lockedState,
  disabled,
  ...props
}: LockableSwitchProps) => {
  const isLocked = lockedState !== null && lockedState !== undefined;
  const isChecked = isLocked ? lockedState : props.checked;
  const isDisabled = isLocked || disabled;

  return (
    <HeadlessSwitch
      {...props}
      checked={isChecked}
      disabled={isDisabled}
      className={`${isChecked ? "bg-blue-500" : "bg-blue-600"}
          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${
            isDisabled ? "cursor-not-allowed bg-gray-300" : ""
          }`}
    >
      <span
        aria-hidden="true"
        className={`${isChecked ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out ${
              isDisabled ? "bg-gray-400" : ""
            }`}
      />
    </HeadlessSwitch>
  );
};
