import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

interface Position {
  x: number;
  y: number;
}

interface MovingPlaceholderProps {
  position: Position;
  children: React.ReactNode;
}

const TRANSITION_DURATION = "0.15s";
const INITIAL_OPACITY = 0;
const VISIBLE_OPACITY = 1;

const MovingPlaceholder: React.FC<MovingPlaceholderProps> = ({
  position,
  children,
}) => {
  const [opacity, setOpacity] = useState(INITIAL_OPACITY);

  const isVisible = position.x !== 0 || position.y !== 0;

  useEffect(() => {
    setOpacity(isVisible ? VISIBLE_OPACITY : INITIAL_OPACITY);
  }, [position, isVisible]);

  if (!isVisible) return null;

  const styles: React.CSSProperties = {
    position: "absolute",
    left: `${position.x}px`,
    top: `${position.y}px`,
    pointerEvents: "none",
    userSelect: "none",
    zIndex: 12,
    overflow: "hidden",
    opacity,
    transform: `scale(${opacity})`,
    transition: `
      opacity ${TRANSITION_DURATION} ease-out,
      transform ${TRANSITION_DURATION} ease-out
    `,
  };

  return (
    <div style={styles} className="text-gray-400">
      {children}
    </div>
  );
};

export default observer(MovingPlaceholder);
