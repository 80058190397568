import Widget, { WidgetProps } from "@/components/Widgets/Widget";

import { WidgetTypes } from "@/components/Widgets";
import { useCallback } from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { Provider, useContentArea } from "../content-area-context";

const useCustomComponent = (ref: React.RefObject<HTMLDivElement>) => {
  const content = useContentArea();
  const { savedSelection } = content;

  const triggerInputEvent = useCallback(() => {
    const input = ref.current;
    input?.dispatchEvent(new Event("input", { bubbles: true }));
  }, [ref]);

  const insertCustomComponent = useCallback(
    (widgetType: WidgetTypes) => {
      if (ref.current) {
        let range: Range;

        if (savedSelection) {
          range = savedSelection;
        } else {
          const selection = window.getSelection();
          range = selection?.getRangeAt(0) || document.createRange();

          if (!ref.current.contains(range.commonAncestorContainer)) {
            range.selectNodeContents(ref.current);
            range.collapse(false);
          }
        }

        // Create component node
        const componentNode = document.createElement("section");
        componentNode.setAttribute("contenteditable", "false");
        componentNode.style.display = "inline-block";
        componentNode.style.whiteSpace = "nowrap";
        componentNode.style.verticalAlign = "baseline";
        componentNode.style.zIndex = "13";
        const componentId = uuidv4();
        componentNode.setAttribute("data-custom-component-id", componentId);

        const newComponent: WidgetProps = {
          id: componentId,
          type: widgetType,
        };

        // Wrap with Provider and render
        const customComponent = (
          <Provider value={content}>
            <Widget {...newComponent} />
          </Provider>
        );
        const root = createRoot(componentNode);
        root.render(customComponent);

        // Handle text node and slash character
        const startContainer = range.startContainer;
        if (startContainer.nodeType === Node.TEXT_NODE) {
          const textNode = startContainer as Text;
          const offset = range.startOffset;

          // Remove the slash character
          if (textNode.textContent) {
            const beforeText = textNode.textContent.slice(
              0,
              Math.max(0, offset - 1),
            );
            const afterText = textNode.textContent.slice(offset) || "\n"; // always make afterText a newline

            // Create text nodes
            if (beforeText) {
              const beforeNode = document.createTextNode(beforeText);
              textNode.parentNode?.insertBefore(beforeNode, textNode);
            }

            // Insert component
            textNode.parentNode?.insertBefore(componentNode, textNode);

            if (afterText) {
              const afterNode = document.createTextNode(afterText);
              textNode.parentNode?.insertBefore(afterNode, textNode);
            }

            // Remove original text node
            textNode.parentNode?.removeChild(textNode);

            // Create a new range after the component
            const newRange = document.createRange();
            newRange.setStartAfter(componentNode);
            newRange.setEndAfter(componentNode);

            // Set selection
            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(newRange);
          }
        } else {
          // If not in a text node, simply insert at current position
          range.insertNode(componentNode);

          // Move cursor after component
          range.setStartAfter(componentNode);
          range.setEndAfter(componentNode);

          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
        }

        // Ensure cursor visibility and component is in view
        requestAnimationFrame(() => {
          componentNode.scrollIntoView({
            block: "nearest",
            inline: "nearest",
            behavior: "smooth",
          });
          ref.current?.focus();
        });

        // Trigger input event
        setTimeout(triggerInputEvent, 0);
      }
    },
    [content, ref, triggerInputEvent, savedSelection],
  );

  return { insertCustomComponent };
};

export default useCustomComponent;
