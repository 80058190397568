import { ScoreCard } from "../types";

const useMatrixTitle = (scoreData?: ScoreCard) => {
  const isFirstRow = (arr: (string | number)[], firstRowLength: number) =>
    arr.length === firstRowLength;

  if (!scoreData?.[0]?.length) return false;

  const firstRow = scoreData[0];
  const firstRowLength = firstRow.length;

  return (
    scoreData
      .slice(1)
      .some((row) => !isFirstRow(row as (string | number)[], firstRowLength)) &&
    typeof firstRow[0] === "string"
  );
};

export default useMatrixTitle;
