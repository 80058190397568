interface PaginationControlsProps {
  currentPage: number;
  maxPages: number;
  onPrevPage: () => void;
  onNextPage: () => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
  maxPages,
  onPrevPage,
  onNextPage,
}) => (
  <div className="flex max-h-96 flex-col items-center justify-center gap-2 overflow-auto">
    {Array.from({ length: maxPages }).map((_, index) => (
      <button
        key={index}
        onClick={() => {
          const diff = index - currentPage;
          const operation = diff > 0 ? onNextPage : onPrevPage;
          for (let i = 0; i < Math.abs(diff); i++) {
            operation();
          }
        }}
        className={`h-12 w-12 rounded border transition-all hover:bg-gray-100 ${
          index === currentPage
            ? "border-blue-500 bg-blue-50 text-white"
            : "border-gray-200 bg-white"
        }`}
      >
        <div className="relative flex h-full w-full items-center justify-center">
          <div className="grid h-8 w-8 grid-cols-2 gap-0.5">
            {Array.from({ length: 4 }).map((_, i) => (
              <div
                key={i}
                className={`${
                  index === currentPage ? "bg-blue-200" : "bg-gray-200"
                }`}
              />
            ))}
          </div>
          <div
            className={`${
              index === currentPage ? "text-blue-500" : "text-gray-500"
            } absolute inset-0 flex items-center justify-center text-sm font-medium`}
          >
            {index + 1}
          </div>
        </div>
      </button>
    ))}
  </div>
);

export default PaginationControls;
