import { JsonBadge } from "@/components/JsonBadge";
import { Button } from "@/components/ui/button";
import { ReportCell } from "@/types/evaluate";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { BracesIcon } from "lucide-react";
import { useState } from "react";
import { renderFunctionDetails } from "../utils";

export const FunctionCallCell = ({ cell }: { cell: ReportCell }) => {
  const [showRawJSON, setShowRawJSON] = useState(false);

  const toggleView = () => {
    setShowRawJSON(!showRawJSON);
  };

  const rawJSON = JSON.stringify(cell.display_value.value, null, 2);

  let content;
  if (cell.display_value.type === "function") {
    content = showRawJSON
      ? rawJSON
      : renderFunctionDetails(
          cell.display_value.value.name,
          cell.display_value.value.arguments,
        );
  } else if (cell.display_value.type === "tool_calls") {
    content = showRawJSON
      ? rawJSON
      : cell.display_value.value.map((toolCall: any) => (
          <div key={toolCall.id}>
            {renderFunctionDetails(
              toolCall.function.name,
              toolCall.function.arguments,
            )}
          </div>
        ));
  }

  return (
    <div className="flex flex-col items-center">
      <pre className={`mb-2 whitespace-pre-wrap font-mono text-gray-800`}>
        {showRawJSON && <JsonBadge />}
        {content}
      </pre>
      <Button
        onClick={toggleView}
        variant={!showRawJSON ? "ghostGray" : "outline"}
        size="tinyButton"
        className="sticky bottom-2 z-10 my-2 flex items-center"
      >
        {showRawJSON ? (
          <>
            <DocumentTextIcon className="mr-1 h-3 w-3" />
            Show function call
          </>
        ) : (
          <>
            <BracesIcon className="mr-1 h-3 w-3" />
            Show raw JSON
          </>
        )}
      </Button>
    </div>
  );
};
