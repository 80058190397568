import { ReportColumn, ReportScore } from "@/types/evaluate";
import {
  SUCCEED_BOOLEAN_THRESHOLD,
  evaluationScoreText,
} from "@/utils/evaluate";
import ScoreMatrix from "./ScoreMatrix";
import MatrixPlaceholder from "./ScoreMatrix/Matrix/MatrixPlaceholder";
import { ScoreCard } from "./ScoreMatrix/types";

export const reportScoreBox = (
  reportLevelScore: ReportScore | null,
  scoredColumns: ReportColumn[],
) => {
  const { scoreFormatted } = evaluationScoreText(reportLevelScore);
  const commonClasses =
    "text-4xl border-2 h-full p-4 rounded-md shadow-md flex justify-center max-w-sm flex-col";

  if (!reportLevelScore) {
    return (
      <div
        className={`${commonClasses} items-center border-gray-200 text-gray-500`}
      >
        <div className="text-lg font-medium">No score.</div>
        <div className="max-w-xs text-xs">
          Report score will be calculated from the last column. Make sure all
          cells are either booleans or numbers.
        </div>
      </div>
    );
  }

  let classes = "";
  let title = "";
  if (reportLevelScore.type === "number") {
    classes = "border-gray-200 text-gray-500";
    title = "Average Score";
  } else if (reportLevelScore.type === "boolean") {
    const success = reportLevelScore.value >= SUCCEED_BOOLEAN_THRESHOLD;
    classes = success
      ? "border-green-200 bg-green-100 text-green-800"
      : "border-red-200 bg-red-100 text-red-800";
    title = "Score";
  }

  const scoreArea =
    scoredColumns.length <= 1 ? (
      <>
        <div className={`${commonClasses} items-center ${classes}`}>
          <div className="text-sm text-gray-600">{title}</div>
          <div>{scoreFormatted}</div>
        </div>
      </>
    ) : (
      <>
        <div className={`${commonClasses} ${classes} text-left`}>
          <div className="flex flex-row gap-x-1 text-base font-semibold text-gray-600">
            <div>Score:</div>
            <div>{scoreFormatted}</div>
          </div>
          {scoredColumns.map((column) => (
            <div
              key={column.id}
              className="flex flex-row justify-between gap-x-2 text-xs text-gray-500"
            >
              <div className="text-left">{column.name}</div>
              <div className="text-right">
                {column.score ? (
                  <>
                    {evaluationScoreText(column.score).scoreFormatted}
                    {column.score.num_skipped &&
                    column.score.num_skipped !== 0 ? (
                      <span> ({column.score.num_skipped} skipped)</span>
                    ) : null}
                  </>
                ) : (
                  <span className="text-gray-300">—</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </>
    );

  return scoreArea;
};

export const ReportScoreCard = ({
  score,
  reportColumns,
  scoreMatrix,
  scoreError,
}: {
  score: ReportScore | null;
  reportColumns: ReportColumn[] | null;
  scoreMatrix?: ScoreCard[];
  scoreError?: string;
}) => {
  const scoredColumns = (reportColumns || [])
    .filter((column) => column.is_part_of_score)
    .sort((a, b) => a.position - b.position);

  if (scoreError) {
    return <MatrixPlaceholder isError={true} input={scoreError} />;
  }

  if (scoreMatrix) {
    return (
      <>
        <ScoreMatrix
          showScore
          showDiff={false}
          beforeScore={{
            score: score?.value ?? (score as unknown as number),
            score_matrix: scoreMatrix,
          }}
          hideTitle
        />
      </>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <div className="flex h-full items-center justify-center pb-4 pt-6">
        {reportScoreBox(score, scoredColumns)}
      </div>
    </div>
  );
};
