import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import WidgetHeader, { WidgetHeaderProps } from "./WidgetHeader";

interface WidgetBaseProps extends WidgetHeaderProps {
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
  onClose?: () => void;
  onDelete?: () => void;
}

const WidgetBase = ({
  title,
  size,
  actions,
  children,
  onClose,
  onDelete,
}: WidgetBaseProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const sizeClasses = {
    small: "w-[300px]",
    medium: "w-[360px]",
    large: "w-[500px]",
  };

  return (
    <div
      className={`relative rounded-lg border bg-gray-50 shadow-sm transition-all duration-200 ${
        sizeClasses[size || "medium"]
      }`}
    >
      <div
        className="flex cursor-pointer items-center overflow-auto rounded-md px-4 py-3 transition-colors hover:bg-gray-100"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? (
          <ChevronRightIcon className="mr-2 h-4 w-4 text-gray-500" />
        ) : (
          <ChevronDownIcon className="mr-2 h-4 w-4 text-gray-500" />
        )}
        <div className="flex-1 select-none">
          <WidgetHeader title={title} actions={actions} />
        </div>
        {onDelete && (
          <Button
            className="ml-2 p-1"
            variant="destructiveLink"
            size="tinyIcon"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <TrashIcon className="h-4 w-4" />
          </Button>
        )}
      </div>
      <div
        className={cn(
          "transition-[max-height,opacity, margin-top] overflow-hidden duration-200 ease-in-out",
          isCollapsed
            ? "mt-0 max-h-0 opacity-0"
            : "mt-2 max-h-[1000px] opacity-100",
        )}
      >
        <div className="flex flex-col gap-y-3 p-4 pt-0">{children}</div>
      </div>
    </div>
  );
};

export default WidgetBase;
