import { ReportColumn, LanguageType } from "@/types/evaluate";
import { useState, useCallback, useMemo } from "react";
import { useBandaid } from "../../../utils/BandaidContext";

type CodeExample = {
  code: string;
  icon: string;
};

export const CodeExamples: Record<LanguageType, Record<string, CodeExample>> = {
  PYTHON: {
    "Process Data": {
      icon: "🔧",
      code: `message = "These are my column names: "
columns = [column_name for column_name in data.keys()]
return message + str(columns)`,
    },
  },
  JAVASCRIPT: {
    "Process Data": {
      icon: "🔧",
      code: `const message = "These are my column names: ";
const columns = Object.keys(data);
return message + JSON.stringify(columns);`,
    },
  },
} as const;

const useColumnLanguage = (columnData: Partial<ReportColumn>) => {
  const selectedLanguage = (columnData.configuration?.language ||
    "PYTHON") as LanguageType;
  const [language, setLanguage] = useState<LanguageType>(selectedLanguage);

  const isBandaid = useBandaid();

  const parse = useCallback(
    (field: string) => (isBandaid ? field.replaceAll("column", "node") : field),
    [isBandaid],
  );

  const [code, setCode] = useState<string>(
    columnData.configuration?.code ||
      parse(CodeExamples[selectedLanguage]["Process Data"]?.code),
  );
  const [selectedExample, setSelectedExample] =
    useState<string>("Process Data");

  const isCustomCode = useMemo(
    () =>
      !Object.values(CodeExamples[language])
        .map((e) => parse(e?.code))
        .includes(code),
    [language, code, parse],
  );

  const handleLanguageChange = useCallback(
    (newLanguage: LanguageType) => {
      setLanguage(newLanguage);
      if (!isCustomCode)
        setCode(parse(CodeExamples[newLanguage][selectedExample]?.code));
    },
    [isCustomCode, parse, selectedExample],
  );

  const handleExampleChange = useCallback(
    (exampleName: string) => {
      setSelectedExample(exampleName);
      setCode(parse(CodeExamples[language][exampleName]?.code));
    },
    [language, parse],
  );

  return {
    language,
    code,
    setCode,
    handleLanguageChange,
    handleExampleChange,
    isCustomCode,
    selectedExample,
  };
};

export default useColumnLanguage;
