import { cn } from "@/lib/utils";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from "react";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <AnimatePresence>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      align="center"
      asChild
      {...props}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.96, y: 2 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.96, y: 2 }}
        transition={{
          type: "spring",
          damping: 20,
          stiffness: 350,
          duration: 0.2,
        }}
      >
        <div
          className={cn(
            "z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground shadow-sm",
            className,
          )}
        >
          <div>{props.children}</div>
          <TooltipPrimitive.Arrow className="fill-black" />
        </div>
      </motion.div>
    </TooltipPrimitive.Content>
  </AnimatePresence>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface BasicTooltipProps {
  content: ReactNode;
  children: ReactNode;
}

const BasicTooltip = ({ content, children }: BasicTooltipProps) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export {
  BasicTooltip,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
};
