import { useRef } from "react";

import useMatrixTitle from "../hooks/useMatrixTitle";
import { ScoreCard } from "../types";
import useScrollControls from "./hooks/useScrollControls";
import MatrixPlaceholder from "./MatrixPlaceholder";
import MatrixRow from "./MatrixRow";
import ScrollButton from "./ScrollButton";
import { isValidMatrix } from "./utils";

export interface MatrixProps {
  scoreCard?: ScoreCard;
  compareCard?: ScoreCard;
  type?: "before" | "after";
  showDiff?: boolean;
}

const Matrix = ({
  scoreCard,
  compareCard,
  type = "before",
  showDiff,
}: MatrixProps) => {
  const hasTitle = useMatrixTitle(scoreCard!);
  const comparisonHasTitle = useMatrixTitle(compareCard!);
  const columnCount = scoreCard?.[0]?.length || 0;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { showLeftScroll, showRightScroll, checkScroll } =
    useScrollControls(scrollContainerRef);

  if (!scoreCard || !isValidMatrix(scoreCard))
    return <MatrixPlaceholder input={scoreCard} isInvalid={!!scoreCard} />;

  if (columnCount === 0) return null;

  const handleScroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="group relative w-full max-w-full rounded-lg border p-4 md:max-w-[400px]">
      <div className="flex flex-col">
        {hasTitle && (
          <div className="mb-4 w-full border-b border-gray-200 pb-3 text-center">
            <h3 className="text-md font-semibold text-gray-900">
              {typeof scoreCard[0][0] === "string" ? scoreCard[0][0] : ""}
            </h3>
          </div>
        )}
        <div
          className="scrollbar-hide relative overflow-x-auto"
          ref={scrollContainerRef}
          onScroll={checkScroll}
        >
          <div
            className={`grid min-w-[400px] gap-1 ${
              columnCount === 1 ? "justify-items-center" : ""
            }`}
            style={{
              gridTemplateColumns:
                columnCount === 1
                  ? "1fr"
                  : `minmax(120px, auto) repeat(${
                      columnCount - (hasTitle ? 2 : 1)
                    }, minmax(80px, 1fr))`,
              minWidth: columnCount > 4 ? "600px" : "auto",
            }}
          >
            {(hasTitle ? scoreCard.slice(1) : scoreCard).map(
              (row, rowIndex) => (
                <MatrixRow
                  key={`${type}-row-${rowIndex}`}
                  row={row}
                  rowIndex={rowIndex}
                  type={type}
                  hasTitle={hasTitle}
                  compareCard={
                    (compareCard &&
                      comparisonHasTitle &&
                      compareCard.slice(1)) ||
                    compareCard
                  }
                  showDiff={showDiff}
                />
              ),
            )}
          </div>
        </div>
      </div>
      {showLeftScroll && (
        <ScrollButton direction="left" onClick={() => handleScroll("left")} />
      )}
      {showRightScroll && (
        <ScrollButton direction="right" onClick={() => handleScroll("right")} />
      )}
    </div>
  );
};

export default Matrix;
