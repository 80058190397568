import useDiffStyles from "../hooks/useDiffStyles";
import { ScoreCard, ScoreMatrixCellValue } from "../types";
import MatrixCell from "./MatrixCell";

const MatrixRow = ({
  row,
  rowIndex,
  type,
  hasTitle,
  compareCard,
  showDiff,
}: {
  row: ScoreMatrixCellValue[];
  rowIndex: number;
  type: string;
  hasTitle: boolean;
  compareCard?: ScoreCard;
  showDiff?: boolean;
}) => {
  const { getCellDiff } = useDiffStyles();

  return (
    <>
      {row.map((cell, cellIndex) => {
        const compareCell = compareCard?.[rowIndex]?.[cellIndex];
        const diffState = getCellDiff(cell, compareCell, showDiff);

        return (
          <MatrixCell
            key={`${type}-cell-${rowIndex}-${cellIndex}`}
            cell={cell}
            compareCell={compareCell}
            type={type}
            cellIndex={cellIndex}
            diffState={diffState}
            isFirstColumn={cellIndex === 0 && typeof cell !== "number"}
          />
        );
      })}
    </>
  );
};

export default MatrixRow;
