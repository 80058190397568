import { autorun } from "mobx";
import { useCallback, useEffect } from "react";
import { useContentArea } from "../ContentArea/content-area-context";

const useWidget = (id: string, value: string) => {
  const content = useContentArea();

  useEffect(() => {
    const disposer = autorun(() => {
      content.setWidget(id, value);
    });

    return disposer;
  }, [content, id, value]);

  const deleteWidget = useCallback(() => {
    const widgetElement = document.querySelector(
      `[data-custom-component-id='${id}']`,
    );

    if (widgetElement) {
      const nextSibling = widgetElement.nextSibling;
      const parentNode = widgetElement.parentNode;
      widgetElement.remove();
      content.removeWidget(id);
      const selection = window.getSelection();
      const range = document.createRange();

      if (nextSibling) {
        range.setStartBefore(nextSibling);
      } else if (parentNode) {
        range.setStartAfter(parentNode.lastChild || parentNode);
      }

      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }, [content, id]);
  return { deleteWidget };
};

export default useWidget;
