import { cn } from "@/lib/utils";
import React from "react";

const Badge = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    variant?: "default" | "positive" | "negative";
  }
>(({ className, variant = "default", ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "relative inline-block px-2 py-1 text-sm font-medium transition-all duration-200 ease-in-out",
        variant === "default" && "bg-gray-100 text-gray-800 hover:bg-gray-200",
        variant === "positive" &&
          "bg-green-100 text-green-800 hover:bg-green-200",
        variant === "negative" && "bg-red-100 text-red-800 hover:bg-red-200",
        className,
      )}
      style={{
        clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
      }}
      {...props}
    />
  );
});
Badge.displayName = "Badge";

export const ComparisonBadge = ({
  percentageDifference,
}: {
  percentageDifference: number;
}) => {
  const isImprovement = percentageDifference > 0;
  const isNoChange = percentageDifference === 0;

  return (
    <div className="flex items-center justify-center">
      <Badge
        variant={
          isNoChange ? "default" : isImprovement ? "positive" : "negative"
        }
        className="text-sm"
      >
        <span className="mr-1">
          {isImprovement ? "↑" : isNoChange ? "–" : "↓"}
        </span>
        {Math.abs(percentageDifference).toFixed(1)}%
      </Badge>
    </div>
  );
};

export default Badge;
