import { useCallback } from "react";

const usePasteHandling = () => {
  const handlePaste = useCallback((event: React.ClipboardEvent) => {
    event.preventDefault();
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    if (!range) return;

    const text = event.clipboardData.getData("text/plain");
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.collapse(false);

    const inputEvent = new Event("input", { bubbles: true });
    event.target.dispatchEvent(inputEvent);
  }, []);

  return { handlePaste };
};

export default usePasteHandling;
