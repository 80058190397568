import { FC } from "react";

const JavaScriptAPIs: FC = () => (
  <ul className="list-disc pl-5">
    <li>
      <strong>Legacy Encoding:</strong> atob, btoa, decodeURI, encodeURI,
      decodeURIComponent, encodeURIComponent
    </li>
    <li>
      <strong>Streams:</strong> ReadableStream, ReadableStreamBYOBReader,
      ReadableStreamBYOBRequest, ReadableStreamDefaultReader,
      ReadableStreamDefaultController, ReadableByteStreamController,
      WritableStream, ByteLengthQueuingStrategy, CountQueuingStrategy,
      TransformStream
    </li>
    <li>
      <strong>URL:</strong> URL, URLSearchParams
    </li>
    <li>
      <strong>Console:</strong> console
    </li>
    <li>
      <strong>Performance:</strong> Performance
    </li>
    <li>
      <strong>Task:</strong> queueMicrotask, setInterval, setTimeout,
      clearInterval, clearTimeout
    </li>
    <li>
      <strong>Location:</strong> WorkerLocation, location
    </li>
    <li>
      <strong>JSON:</strong> JSON
    </li>
    <li>
      <strong>Encoding:</strong> TextEncoder, TextDecoder, CompressionStream,
      DecompressionStream
    </li>
    <li>
      <strong>Structured Clone:</strong> structuredClone
    </li>
    <li>
      <strong>Fetch:</strong> fetch, Request, Response, Headers
    </li>
    <li>
      <strong>Crypto:</strong> SubtleCrypto, Crypto, crypto, CryptoKey
    </li>
  </ul>
);

export default JavaScriptAPIs;
