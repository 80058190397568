import { Button, DivButton } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useConfigureReportScoreCard } from "@/queries";
import { LanguageType, ReportColumn } from "@/types/evaluate";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { ReactNode, useMemo, useRef, useState } from "react";
import ConfirmationModal from "../ConfirmationModal";
import LoadingSpinner from "../LoadingSpinner";
import Checkbox from "../ui/checkbox";
import { useReportContext } from "./report-context";
import CodeEditor from "./TableComponents/NewColumnModal/CodeType/CodeEditor";
import useMonacoCompletion from "./TableComponents/NewColumnModal/CodeType/hooks/useMonacoCompletion";

const ConfigureScoreCardModal = ({ children }: { children: ReactNode }) => {
  const reportContext = useReportContext();
  const [open, setOpen] = useState(false);

  if (reportContext.reportColumnsIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="max-w-3xl">
        <ConfigureScoreCardModalHelper setOpen={setOpen} children={children} />
      </DialogContent>
    </Dialog>
  );
};

const ConfigureScoreCardModalHelper = ({
  children,
  setOpen,
}: {
  children: ReactNode;
  setOpen: (open: boolean) => void;
}) => {
  const reportContext = useReportContext();

  const reportMetadata = reportContext.reportMetadata;

  const scoreConfiguration = reportMetadata?.score_configuration;

  const availableColumns: ReportColumn[] = useMemo(
    () => reportContext.sortedColumns || [],
    [reportContext.sortedColumns],
  );
  const reportId = useMemo(
    () => reportContext.reportId,
    [reportContext.reportId],
  );

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    availableColumns
      .filter((column) => column.is_part_of_score)
      .map((column) => column.name),
  );
  const [useCustomCode, setUseCustomCode] = useState(!!scoreConfiguration);
  const [language, setLanguage] = useState<LanguageType>(
    scoreConfiguration?.code_language || "PYTHON",
  );
  const pythonPlaceholder = `# The variable 'data' is a list of rows.
# Each row is a dictionary of column name -> value
# For example: [
#       {'columnA': 1, 'columnB': 2},
#       {'columnA': 4, 'columnB': 1}
#  ]
#
# Must return a dictionary with the following structure:
# {
#   'score': number,  # Required
#   'score_matrix': [[[number | string, ...], ...]...],  # Optional - list of lists of lists of elements
# }

return {
    'score': len(data),
    'score_matrix': [[
        ["Criteria", "Weight", "Value"],
        ["Correctness", 4, 7],
        ["Completeness", 3, 6],
        ["Accuracy", 5, 8],
        ["Relevance", 4, 9]
    ]],
}`;

  const javascriptPlaceholder = `// The variable 'data' is an array of rows.
// Each row is an object of column name -> value
// For example: [
//       {'columnA': 1, 'columnB': 2},
//       {'columnA': 4, 'columnB': 1}
//  ]
//
// Must return an object with the following structure:
// {
//   score: number,  // Required
//   score_matrix: (number|string)[][][],  // Optional - array of arrays of arrays of elements
// }

return {
    score: data.length,
    score_matrix: [[
        ["Criteria", "Weight", "Value"],
        ["Correctness", 4, 7],
        ["Completeness", 3, 6],
        ["Accuracy", 5, 8],
        ["Relevance", 4, 9]
    ]],
};`;

  const getPlaceholderCode = (lang: LanguageType) => {
    return lang === "PYTHON" ? pythonPlaceholder : javascriptPlaceholder;
  };

  const [customCode, setCustomCode] = useState(
    scoreConfiguration?.code || getPlaceholderCode(language),
  );

  const userToken = useAuth()?.userToken || "";
  const { mutate: configureScoreCard, isLoading: configureScoreCardIsLoading } =
    useConfigureReportScoreCard(userToken || "", reportId);

  const handleConfigureScoreCard = () => {
    if (!reportContext.isEditable && !showConfirmation) {
      if (containerRef.current) containerRef.current.scrollTop = 0;
      setShowConfirmation(true);
      return;
    }
    configureScoreCard(
      {
        columnNames: selectedColumns,
        code: useCustomCode ? customCode : undefined,
        codeLanguage: useCustomCode ? language : undefined,
      },
      {
        onSuccess: () => {
          displayToast("Scorecard configuration saved!", ToastType.success);
          setOpen(false);
        },
        onError: (error: any) => {
          const message =
            error?.message || error || "Error saving scorecard configuration";
          displayErrorToast(message);
        },
      },
    );
  };

  const notYetChosenColumns = useMemo(() => {
    return availableColumns.filter(
      (column) => !selectedColumns.includes(column.name),
    );
  }, [selectedColumns, availableColumns]);

  const addColumnName = () => {
    setSelectedColumns([...selectedColumns, ""]);
  };

  const removeColumnName = (index: number) => {
    const newColumnNames = [...selectedColumns];
    newColumnNames.splice(index, 1);
    setSelectedColumns(newColumnNames);
  };

  const updateColumnName = (index: number, newName: string) => {
    const newColumnNames = [...selectedColumns];
    newColumnNames[index] = newName;
    setSelectedColumns(newColumnNames);
  };

  useMonacoCompletion(
    language,
    availableColumns.map((c) => c.name),
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <div className="relative flex h-[80vh] flex-col">
      <div
        className="mb-6 flex-1 space-y-6 overflow-y-auto p-6"
        ref={containerRef}
      >
        <div>
          <h2 className="text-2xl font-semibold text-gray-900">
            Configure Scorecard
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            Select columns to include in your evaluation scorecard. The score
            will be calculated using the selected columns.
            <DivButton
              variant="link"
              className="m-0 p-0 px-2"
              onClick={() =>
                window.open(
                  "https://docs.promptlayer.com/features/evaluations/score-card",
                  "_blank",
                )
              }
            >
              Learn more
            </DivButton>
          </p>

          <ConfirmationModal
            title={"Warning"}
            description={
              "Scorecard will only change will only change for this evaluation, not the whole pipeline."
            }
            onConfirm={function () {
              handleConfigureScoreCard();
            }}
            open={!reportContext.isEditable && showConfirmation}
            setOpen={setShowConfirmation}
          />
        </div>

        <div className="space-y-4">
          <div className="rounded-lg border border-gray-200 p-4">
            <h3 className="mb-3 font-medium text-gray-700">Score Columns</h3>
            <div className="max-h-[30vh] space-y-2 overflow-y-auto">
              {selectedColumns.map((columnName, index) => (
                <div key={index} className="flex items-center gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex-1 truncate rounded-md border border-gray-200 px-3 py-2 text-left hover:bg-gray-50">
                      {columnName || "Select a column..."}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-h-[40vh] overflow-y-auto">
                      {notYetChosenColumns.map((column) => (
                        <DropdownMenuItem
                          key={column.name}
                          onSelect={() => updateColumnName(index, column.name)}
                        >
                          {column.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => removeColumnName(index)}
                    className="flex-shrink-0 text-gray-500 hover:text-red-600"
                  >
                    <XIcon className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <Button
                variant="outline"
                size="sm"
                onClick={addColumnName}
                className="mt-2"
              >
                <PlusIcon className="mr-1 h-4 w-4" />
                Add Column
              </Button>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              checked={useCustomCode}
              onChange={(e) => setUseCustomCode(e.target.checked)}
              label="Use Custom Scoring Logic"
            />
          </div>

          {useCustomCode && (
            <div className="space-y-4 rounded-lg border border-gray-200 p-4">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">
                  Programming Language
                </span>
                <DropdownMenu>
                  <DropdownMenuTrigger className="w-32 rounded-md border border-gray-200 px-3 py-1 text-sm">
                    {language.charAt(0).toUpperCase() +
                      language.slice(1).toLowerCase()}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {["Python", "Javascript"].map((lang) => (
                      <DropdownMenuItem
                        key={lang}
                        onSelect={() => {
                          setLanguage(lang.toUpperCase() as LanguageType);
                          if (
                            [javascriptPlaceholder, pythonPlaceholder].includes(
                              customCode,
                            )
                          ) {
                            setCustomCode(
                              getPlaceholderCode(
                                lang.toUpperCase() as LanguageType,
                              ),
                            );
                          }
                        }}
                      >
                        {lang}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <CodeEditor
                language={language.toLowerCase()}
                code={customCode}
                setCode={(value) => setCustomCode(value || "")}
                editorOptions={{}}
              />
            </div>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 right-0 z-30 flex justify-end p-4">
        <Button
          variant="default"
          onClick={handleConfigureScoreCard}
          isLoading={configureScoreCardIsLoading}
          className="w-full sm:w-auto"
        >
          {"Save Configuration"}
        </Button>
      </div>
    </div>
  );
};

export default ConfigureScoreCardModal;
