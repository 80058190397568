import { Button } from "@/components/ui/button";
import { CopyButton } from "@/components/ui/copy-button";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { DashboardIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useState } from "react";

const getStateColors = (isError: boolean, isInvalid: boolean) => {
  if (isInvalid || isError)
    return {
      border: "border-red-200",
      borderFaded: "border-red-200/50",
      bg: "bg-red-50/30",
      hoverBg: "bg-red-100/20",
      text: "text-red-600",
      hoverText: "text-red-700",
      hoverBgFull: "bg-red-50/30",
      icon: "text-red-500",
      scrollbar: "scrollbar-thumb-red-100",
    };
  return {
    border: "border-gray-200",
    borderFaded: "border-gray-200/50",
    bg: "bg-gray-50/30",
    hoverBg: "bg-gray-100/20",
    text: "text-gray-600",
    hoverText: "text-gray-700",
    hoverBgFull: "bg-gray-50/30",
    icon: "text-gray-500",
    scrollbar: "scrollbar-thumb-gray-100",
  };
};

const GridCell = ({
  isInvalid,
  isError,
}: {
  isInvalid: boolean;
  isError: boolean;
}) => {
  const colors = getStateColors(isError, isInvalid);
  return (
    <div
      className={`h-full w-full rounded-sm border ${colors.borderFaded} ${colors.bg} group-hover:${colors.hoverBg} transition-all duration-150`}
    />
  );
};

const IconWrapper = ({
  isInvalid,
  isError,
}: {
  isInvalid: boolean;
  isError: boolean;
}) => {
  const colors = getStateColors(isError, isInvalid);
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className={`rounded-lg bg-white/90 p-2.5 shadow-md `}>
        {isError || isInvalid ? (
          <ExclamationTriangleIcon className={`h-6 w-6 ${colors.icon}`} />
        ) : (
          <DashboardIcon className={`h-6 w-6 ${colors.icon}`} />
        )}
      </div>
    </div>
  );
};

const CollapsedContent = ({
  isInvalid,
  isError,
}: {
  isInvalid: boolean;
  isError: boolean;
}) => (
  <div className="relative grid h-28 w-28 grid-cols-3 gap-1">
    {Array.from({ length: 9 }).map((_, i) => (
      <GridCell key={i} isInvalid={isInvalid} isError={isError} />
    ))}
    <IconWrapper isInvalid={isInvalid} isError={isError} />
  </div>
);

const ExpandedContent = ({
  input,
  isInvalid,
  isError,
}: {
  input: unknown;
  isInvalid: boolean;
  isError: boolean;
}) => {
  const colors = getStateColors(isError, isInvalid);
  return (
    <div
      className={`w-full max-w-md overflow-hidden rounded-lg border ${colors.border} bg-white shadow-sm`}
    >
      <div className="flex items-center justify-between border-b border-gray-100 px-4 py-2">
        <h3 className="text-xs font-medium text-gray-700">
          {isError ? "Error Message" : "Matrix Data"}
        </h3>
        <CopyButton text={JSON.stringify(input, null, 2) as string} />
      </div>
      <pre
        className={`scrollbar-thin ${colors.scrollbar} scrollbar-track-neutral-50 max-h-[100px] max-w-full overflow-auto whitespace-pre-wrap p-4 text-xs text-neutral-600`}
      >
        {typeof input === "string" ? input : JSON.stringify(input, null, 2)}
      </pre>
    </div>
  );
};

const ExpandButton = ({
  isExpanded,
  setIsExpanded,
  isInvalid,
  isError,
}: {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  isInvalid: boolean;
  isError: boolean;
}) => {
  const colors = getStateColors(isError, isInvalid);
  return (
    <Button
      onClick={() => setIsExpanded(!isExpanded)}
      variant={"ghost"}
      size={"sm"}
      className={`gap-x-1 ${colors.text} hover:${colors.hoverBgFull} hover:${colors.hoverText}`}
    >
      {isExpanded ? "Hide Details" : "Show Details"}
      <ChevronDownIcon
        className={`h-3.5 w-3.5 transition-transform duration-150 ${
          isExpanded ? "rotate-180 transform" : ""
        }`}
      />
    </Button>
  );
};

const MatrixPlaceholder = ({
  input = "",
  isInvalid = false,
  isError = false,
}: {
  input?: unknown;
  isInvalid?: boolean;
  isError?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const colors = getStateColors(isError, isInvalid);

  return (
    <div
      className={`flex h-[250px] w-full max-w-md flex-col items-center justify-center gap-3 rounded-lg border ${colors.border} hover:${colors.hoverBgFull} bg-white p-6 transition-all duration-150 sm:w-[400px]`}
    >
      {isExpanded ? (
        <ExpandedContent
          input={input}
          isInvalid={isInvalid}
          isError={isError}
        />
      ) : (
        <CollapsedContent isInvalid={isInvalid} isError={isError} />
      )}

      <div className="flex flex-col items-center gap-1.5">
        <span className={`text-sm font-medium ${colors.text}`}>
          {isError
            ? "Execution Error"
            : isInvalid
            ? "Invalid Matrix Format"
            : "No Matrix Data"}
        </span>
        {(isInvalid || isError) && (
          <ExpandButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            isInvalid={isInvalid}
            isError={isError}
          />
        )}
      </div>
    </div>
  );
};

export default MatrixPlaceholder;
