import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { format } from "date-fns";
import { ChevronLeft, ChevronRight } from "lucide-react";

import { observer } from "mobx-react-lite";
import {
  getFormattedScore,
  useCompareReportsStore,
} from "./compare-reports-store";

import { Report } from "@/types/evaluate";

interface BatchSelectorProps {
  label: string;
  value: Report | null;
  page: number;
  setPage: (page: number) => void;
  onChange: (batchId: string) => void;
  totalPages?: number;
}

const BatchSelector = observer(
  ({
    label,
    value,
    page,
    setPage,
    totalPages,
    onChange,
  }: BatchSelectorProps) => {
    const { batches, isLoading } = useCompareReportsStore();

    if (isLoading) {
      return <Skeleton className="h-10 w-full" />;
    }

    const handleClose = () => {
      setPage(1);
    };

    const handlePageChange = (newPage: number) => {
      setPage(Math.max(1, Math.min(newPage, totalPages!)));
    };

    return (
      <Select
        onValueChange={onChange}
        value={value?.name}
        onOpenChange={(open) => !open && handleClose()}
      >
        <SelectTrigger className="w-full flex-1">
          {value ? (
            <div className="flex w-full items-center justify-between">
              <span className="mr-2 max-w-[200px] truncate font-medium">
                {value.name}
              </span>
              <div className="flex flex-shrink-0 flex-row gap-x-2 text-right">
                <span className="block text-xs text-gray-500">
                  {format(new Date(value.created_at || ""), "MMM d, yyyy")}
                </span>
                {value?.score !== null && (
                  <span className="block text-xs text-gray-500">
                    Score: {getFormattedScore(value)}%
                  </span>
                )}
              </div>
            </div>
          ) : (
            `Select ${label}`
          )}
        </SelectTrigger>
        <SelectContent className="relative ">
          {batches.map((batch) => (
            <SelectItem key={batch.id} value={String(batch.id)}>
              <div className="flex flex-col">
                <span className="font-medium">{batch.name}</span>
                <span className="text-xs text-gray-500">
                  {format(new Date(batch.created_at), "MMM d, yyyy")}
                  {batch?.score !== null &&
                    ` • Score: ${getFormattedScore(batch)}%`}
                </span>
              </div>
            </SelectItem>
          ))}
          <div className="relative mt-2 flex w-full items-center justify-between">
            <Button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1 || !totalPages}
              size="sm"
              variant="outline"
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <span className="text-sm">{`Page ${page} of ${totalPages}`}</span>
            <Button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages || !totalPages}
              size="sm"
              variant="outline"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </SelectContent>
      </Select>
    );
  },
);

export default BatchSelector;
