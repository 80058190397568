import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ToastType } from "@/enums";
import { displayToast } from "@/utils/toast";
import { ExclamationIcon } from "@heroicons/react/outline";
import { FC } from "react";

type ConfirmationModalProps = {
  title: string;
  description: string;
  onConfirm: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  description,
  onConfirm,
  open,
  setOpen,
}: ConfirmationModalProps) => {
  const handleConfirm = async () => {
    try {
      onConfirm();
      setOpen(false);
    } catch (error: unknown) {
      console.error("Error confirming action:", error);
      displayToast("Failed to complete action", ToastType.error);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center gap-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-amber-100">
              <ExclamationIcon className="h-5 w-5 text-amber-600" />
            </div>
            <DialogTitle className="text-lg font-semibold">{title}</DialogTitle>
          </div>
        </DialogHeader>
        <div className=" text-gray-600">{description}</div>
        <DialogFooter className="mt-6 gap-2">
          <Button
            className="text-sm"
            onClick={() => setOpen(false)}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
