import { cn } from "@/lib/utils";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { TextareaProps } from "../ui/textarea";
import { WidgetTypes } from "../Widgets";
import useInitialWidgets from "../Widgets/useInitialWidgets";
import { useContentArea } from "./content-area-context";
import ContextMenu from "./ContextMenu";
import { useContentAreaConfig } from "./helpers/content-area-config-context";
import { useCustomComponent } from "./hooks";
import useCursorPosition from "./hooks/handlers/useCursorPosition";
import useHandlers from "./hooks/useContentHandlers";
import MovingPlaceholder from "./MovingPlaceholder";

interface ContentProps extends TextareaProps {
  readOnly?: boolean;
  isXrayMode?: boolean;
  placeholder?: string;
}

const Content = observer((props: ContentProps) => {
  const {
    features: { widgets },
  } = useContentAreaConfig();

  const {
    readOnly = false,
    isXrayMode = false,
    placeholder = `${
      (widgets && "Type / to use a widget...") || "Type here..."
    }`,
    ...textareaProps
  } = props;
  const content = useContentArea();
  const initialContent = content.html;

  const contentRef = useRef<HTMLDivElement>(null);
  const updateCursorPosition = useCursorPosition(contentRef);
  const { insertCustomComponent } = useCustomComponent(contentRef);

  const handles = useHandlers(contentRef, widgets);
  useInitialWidgets(contentRef, initialContent, readOnly, isXrayMode);

  useEffect(() => {
    content.setContent(content.html);

    const disp = reaction(
      () => content.getContent(),
      (newContent) => {
        textareaProps?.onChange?.({
          target: { value: newContent },
        } as any);
      },
    );
    return disp;
  }, [content, textareaProps, textareaProps.onChange]);

  const [isFocused, setFocused] = useState(false);

  const hidePlaceholder = !isFocused && !!content.getContent().trim();

  return (
    <div className="relative h-full w-full overflow-hidden">
      <div className="relative inline-block h-full w-full overflow-hidden">
        {!hidePlaceholder &&
          content.isCurrentLineEmpty &&
          content.savedSelection?.collapsed && (
            <MovingPlaceholder position={content.absCaretPos}>
              {placeholder}
            </MovingPlaceholder>
          )}
        <div
          ref={contentRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          contentEditable={!readOnly}
          suppressContentEditableWarning
          onScroll={() => updateCursorPosition()}
          {...(readOnly ? {} : handles)}
          onKeyDown={(e) => {
            if (!readOnly) {
              handles.onKeyDown(e);
              textareaProps.onKeyDown?.(e as any);
            }
          }}
          style={{
            minHeight: "60px",
            height: "100%",
            lineHeight: "1.5",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            display: "inline-block",
            width: "100%",
          }}
          role={readOnly ? "region" : "textbox"}
          aria-readonly={readOnly}
          aria-multiline={!readOnly}
          className={cn(
            "relative z-10 w-full overflow-auto border border-gray-200 px-3 py-2 transition-colors duration-200",
            readOnly
              ? "cursor-default"
              : "hover:border-gray-300 focus:border-transparent  ",
            props.className,
            !props.className && "shadow-sm",
          )}
        />
      </div>
      {!readOnly && (
        <ContextMenu
          isVisible={content.showContextMenu}
          position={{
            left: content.caretPosition.x,
            top: content.caretPosition.y,
          }}
          onSelect={(item: string) => {
            // Handle the selected item
            insertCustomComponent(item as WidgetTypes);
            content.setShowContextMenu(false);
          }}
          onClose={() => content.setShowContextMenu(false)}
          contentRef={contentRef}
        />
      )}
    </div>
  );
});

export default Content;
