import { ChooseWorkspaceAndRedirect } from "@/UserIndex";
import DatasetEditor from "@/components/DatasetEditor";
import Datasets from "@/components/Datasets";
import Home from "@/components/Home";
import Registry from "@/components/Registry";
import SharableRequestLayout from "@/components/SharableRequestLayout";
import SidebarSearchLayoutWrapper from "@/components/SidebarSearchLayoutWrapper";
import * as Sentry from "@sentry/react";
import React, { lazy, useEffect } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import AdminLogin from "./AdminLogin";
import "./App.css";
import CreateAccount from "./CreateAccount";
import DiffPromptTemplate from "./DiffPromptTemplate";
import GroupInfo from "./GroupInfo";
import Login from "./Login";
import LoginLayout from "./LoginLayout";
import Logout from "./Logout";
import NotFound from "./NotFound";
import PromptTemplate from "./PromptTemplate";
import RequestInfo from "./RequestInfo";
import ResetPassword from "./ResetPassword";
import Settings from "./Settings";
import SharedRequestInfo from "./SharedRequestInfo";
import Evaluate from "./components/Evaluate";
import CreateBlueprint from "./components/Evaluate/Blueprints/CreateBlueprint";
import EditBlueprint from "./components/Evaluate/Blueprints/EditBlueprint";
import ViewReport from "./components/Evaluate/Reports/ViewReport";
import WorkflowGrid from "./components/Registry/WorkflowGrid";
import ReleaseLabelGroup from "./components/ReleaseLabelGroup";
import ReleaseLabelGroups from "./components/ReleaseLabelGroups";
import TraceDetail from "./components/TraceDetail";

import CompareReports from "./components/Evaluate/CompareReports";
import CreateWorkflow from "./components/Workflows/CreateWorkflow";
import Workflow from "./components/Workflows/WorkflowVersion";
import ErrorFallback, {
  RouteErrorFallback,
} from "./components/ui/error-fallback";
import { useAuth } from "./context/auth-context";
import { UserProvider } from "./context/user-context";
import useGclidTracking from "./hooks/useGclidTracking";
import { Analytics as UsageAnalytics } from "./routes";
import "./styles/fonts.css";

const CreatePrompt = lazy(() => import("@/routes/prompts/create/page"));
const EditPrompt = lazy(() => import("@/routes/prompts/edit/page"));
const RequestLogPlayground = lazy(
  () => import("@/routes/request-logs/playground/page"),
);
const PromptVersionPlayground = lazy(
  () => import("@/routes/prompt-versions/playground/page"),
);
const Playground = lazy(() => import("@/routes/playground/page"));

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://39c29fa0d76cc7d3f34e9911ffc7ac89@o4505329762369536.ingest.sentry.io/4505982966890496",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const JOBS_URL =
  "https://magniv.notion.site/PromptLayer-is-Hiring-828b26be55484526a50115f7f47cd5bc";

function ExternalNavigation({ url }: { url: string }) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <a href={url}>Click here if you are not redirected.</a>;
}

export default function App() {
  const auth = useAuth();
  useGclidTracking();
  const isLoggedIn = !!auth?.userToken && !!auth.user;

  const requiresAuth = (comp: React.ReactElement) => {
    return isLoggedIn ? comp : <Navigate to="/login" />;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<RouteErrorFallback />}>
        <Route
          path="/home"
          element={
            <UserProvider>
              <ChooseWorkspaceAndRedirect />
            </UserProvider>
          }
        />

        <Route
          path="/workspace/:workspaceId"
          element={
            <UserProvider>
              {requiresAuth(<SidebarSearchLayoutWrapper />)}
            </UserProvider>
          }
        >
          <Route path="" element={<Navigate to="/home" replace />} />
          <Route path="ab-releases" element={<ReleaseLabelGroups />} />
          <Route path="workflow" element={<WorkflowGrid />} />
          <Route
            path="ab-releases/workflow-release-group/:releaseLabelGroupId"
            element={<ReleaseLabelGroup isWorkflow={true} />}
          />
          <Route
            path="ab-releases/prompt-release-group/:releaseLabelGroupId"
            element={<ReleaseLabelGroup />}
          />
          <Route path="home" element={<Home />} />
          <Route path="request/:requestId">
            <Route path="playground" element={<RequestLogPlayground />} />
            <Route index element={<RequestInfo />} />
          </Route>
          <Route path="traces" element={<Navigate to="/home" replace />} />
          <Route path="request" element={<Navigate to="/home" replace />} />

          <Route path="group/:groupId" element={<GroupInfo />} />
          <Route path="prompt" element={<Registry />} />
          <Route path="prompt-folder/:folderId" element={<Registry />} />
          <Route path="datasets" element={<Datasets />} />
          <Route
            path="dataset-groups/:datasetGroupId"
            element={<DatasetEditor />}
          />
          <Route
            path="dataset-groups/:datasetGroupId/dataset/:datasetId"
            element={<DatasetEditor />}
          />
          <Route path="traces/:traceId" element={<TraceDetail />} />
          <Route path="analytics" element={<UsageAnalytics />} />
          <Route path="evaluate" element={<Evaluate />} />
          <Route path="evaluate/compare" element={<CompareReports />} />
          <Route
            path="evaluate/create-blueprint"
            element={<CreateBlueprint />}
          />
          <Route
            path="evaluate/blueprints/:blueprintId"
            element={<EditBlueprint />}
          />
          <Route path="evaluate/reports/:reportId" element={<ViewReport />} />
          <Route path="evaluate" element={<Evaluate />} />
          <Route
            path="evaluate/create-blueprint"
            element={<CreateBlueprint />}
          />
          <Route
            path="evaluate/blueprints/:blueprintId"
            element={<EditBlueprint />}
          />
          <Route path="evaluate/reports/:reportId" element={<ViewReport />} />
          <Route path="workflow/:workflowId/version/:versionId">
            <Route path="playground" element={null} />
            <Route index element={<Workflow />} />
          </Route>
          <Route path="workflow/:workflowId" element={<Workflow />} />
          <Route
            path="workflow/:workflowId/version/:versionId/edit"
            element={<Workflow editing={true} />}
          />

          <Route
            path="workflow/:workflowId/edit"
            element={<Workflow editing={true} />}
          />
          <Route path="create-workflow" element={<CreateWorkflow />} />

          <Route path="prompt/:promptId/version/:versionId">
            <Route path="playground" element={<PromptVersionPlayground />} />
            <Route index element={<PromptTemplate />} />
          </Route>
          <Route path="prompt/:promptId" element={<PromptTemplate />} />
          <Route
            path="prompt/:promptId/:versionNumber/edit"
            element={<EditPrompt />}
          />
          <Route
            path="prompt/:promptId/version/:versionNumber/diff/:diffVersionNumber"
            element={<DiffPromptTemplate />}
          />
          <Route path="create-prompt" element={<CreatePrompt />} />
          <Route path="playground" element={<Playground />} />
          <Route path="settings" element={<Settings />} />
          <Route path="prompts">
            <Route path="create" element={<CreatePrompt />} />
          </Route>
        </Route>
        <Route
          path="/share/:shareHash"
          element={
            <SharableRequestLayout>
              <SharedRequestInfo />
            </SharableRequestLayout>
          }
        />
        <Route
          path="/create-account"
          element={
            <LoginLayout>
              <CreateAccount />
            </LoginLayout>
          }
        />
        <Route
          path="/colonial-scripts-Z8xLp"
          element={
            <LoginLayout>
              <AdminLogin />
            </LoginLayout>
          }
        />
        <Route
          path="/login"
          element={
            <LoginLayout>
              <Login />
            </LoginLayout>
          }
        />
        <Route
          path="/reset-password"
          element={
            <LoginLayout>
              <ResetPassword />
            </LoginLayout>
          }
        />
        <Route
          path="/logout"
          element={
            <UserProvider>
              <Logout />
            </UserProvider>
          }
        />
        <Route path="/jobs" element={<ExternalNavigation url={JOBS_URL} />} />
        <Route
          path="/"
          element={
            isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/not-found"
          element={
            <LoginLayout>
              <NotFound />
            </LoginLayout>
          }
        />
        <Route
          path="*"
          element={
            <LoginLayout>
              <NotFound />
            </LoginLayout>
          }
        />
      </Route>,
    ),
  );

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  );
}
