import React from "react";

const Scoreboard: React.FC<{
  score1: number;
  score2: number;
}> = ({ score1, score2 }) => {
  const getColorClass = (score: number) => {
    return score >= 70
      ? "border-green-200 bg-green-100 text-green-800"
      : "border-red-200 bg-red-100 text-red-800";
  };

  return (
    <div className="mb-6 rounded-lg border border-gray-200 bg-white p-6">
      <div className="flex flex-col items-center">
        <div className="flex w-full max-w-md justify-between">
          <div
            className={`flex flex-col items-center rounded-lg border p-4 ${getColorClass(
              score1,
            )}`}
          >
            <div className="mb-2 text-sm font-medium">Score A</div>
            <div className="text-4xl font-bold">{score1}%</div>
          </div>
          <div className="flex items-center justify-center">
            <div className="mx-6 h-16 w-px bg-gray-200"></div>
          </div>
          <div
            className={`flex flex-col items-center rounded-lg border p-4 ${getColorClass(
              score2,
            )}`}
          >
            <div className="mb-2 text-sm font-medium">Score B</div>
            <div className="text-4xl font-bold">{score2}%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scoreboard;
