import { FC } from "react";
import JavaScriptAPIs from "./JavascriptAPIs";
import RuntimeQuota from "./RuntimeQuota";
import ToggleDetailsButton from "./ToggleDetailsButton";
interface RuntimeDetailsProps {
  language: string;
  showRuntimeDetails: boolean;
  setShowRuntimeDetails: (show: boolean) => void;
}
const RuntimeDetails: FC<RuntimeDetailsProps> = ({
  language,
  showRuntimeDetails,
  setShowRuntimeDetails,
}) => {
  if (language === "PYTHON") {
    return (
      <div>
        The Python runtime runs Python 3.12.0.{" "}
        <ToggleDetailsButton
          showRuntimeDetails={showRuntimeDetails}
          onClick={() => setShowRuntimeDetails(!showRuntimeDetails)}
        />
        {showRuntimeDetails && (
          <div className="mt-2 max-h-40 overflow-y-auto rounded-md border bg-gray-100 p-2 text-xs">
            There is no filesystem access. There is no network access.
            <br />
            <br />
            Only the standard library is available.
            <br />
            <br />
            <p>
              <strong>Resource Quota:</strong>
            </p>
            <RuntimeQuota />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      The JavaScript runtime is built on Mozilla's SpiderMonkey engine.{" "}
      <ToggleDetailsButton
        showRuntimeDetails={showRuntimeDetails}
        onClick={() => setShowRuntimeDetails(!showRuntimeDetails)}
      />
      {showRuntimeDetails && (
        <div className="mt-2 max-h-40 overflow-y-auto rounded-md border bg-gray-100 p-2 text-xs">
          There is no filesystem access. There is no network access.
          <br />
          <br />
          In particular, it is not node or deno.
          <br />
          <br />
          <p>
            <strong>Available APIs:</strong>
          </p>
          <JavaScriptAPIs />
          <br />
          <p>
            <strong>Resource Quota:</strong>
          </p>
          <RuntimeQuota />
        </div>
      )}
    </div>
  );
};
export default RuntimeDetails;
