import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCompareReportsStore } from "../compare-reports-store";
import { useGetReport } from "@/queries";
import { useAuth } from "@/context/auth-context";
import { autorun, runInAction } from "mobx";

const useDefaultReport = () => {
  const [searchParams] = useSearchParams();
  const reportStore = useCompareReportsStore();
  const reportId = searchParams.get("report_id");
  const { workspaceId } = useParams();
  const { setSelectedBatch, addBatch } = reportStore;
  const reportIdNumber = reportId ? Number(reportId) : null;
  const userToken = useAuth()?.userToken || "";

  const { isLoading, data } = useGetReport(userToken!, reportIdNumber);

  useEffect(() => {
    runInAction(() => {
      reportStore.reset();
    });
  }, [workspaceId, reportStore]);

  useEffect(() => {
    autorun(() => {
      if (!isLoading && data) {
        runInAction(() => {
          addBatch(data);
          setSelectedBatch(reportIdNumber!, "A");
        });
      }
    });
  }, [addBatch, data, isLoading, reportIdNumber, setSelectedBatch]);
};

export default useDefaultReport;
